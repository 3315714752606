<template>
  <v-dialog
    :retain-focus="false"
    no-click-animation
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card tile>
      <v-app-bar color="primary" dark fixed>
        <v-container class="d-flex align-center pa-0">
          <v-btn class="mr-3" small icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="mt-1">
            <div>
              <h1 class="title font-weight-bold" style="line-height: 1.3">
                Vincular proposta
              </h1>
            </div>
          </v-toolbar-title>
        </v-container>
      </v-app-bar>

      <div class="header-height"></div>

      <v-container class="pa-5 pt-5">
        <div
          v-text="'Selecione um lead existente:'"
          class="text-h6 mb-3"
        ></div>

        <base-text-field
          id="nome"
          name="nome"
          v-model="filtros.textSearch"
          @input="debounceInput"
          label="Busque por nome, email ou celular"
          clearable
          outlined
          prepend-inner-icon="mdi-magnify"
        ></base-text-field>

        <v-row justify="end">
          <v-col sm="6" md="3">
            <div class="d-flex justify-center">
              <div
                v-text="'Filtros:'"
                class="text-body-1 pt-4 pr-2"
              >
              </div>
              <base-autocomplete-field
                :items="optionsStatus"
                id="status"
                name="status"
                label="Status"
                v-model="filtros.status"
                @input="debounceInput"
                item-text="titulo"
                item-value="valor"
                outlined
              ></base-autocomplete-field>
            </div>
          </v-col>
        </v-row>
        <div class="text-center">
          {{ descricaoRegistrosEncontrados }}
        </div>
        <div style="overflow-y: auto; height: 350px;">
          <v-data-table
            v-model="table.selected"
            :headers="table.headers"
            :items="desserts"
            :single-select="table.singleSelect"
            item-key="id"
            show-select
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.nome`]="{ item }">
              {{ item.nome || '--' }}
            </template>
            <template v-slot:[`item.telefone`]="{ item }">
              {{ item.telefone ? `(${item.telefone.ddd}) ${item.telefone.numero}` : '--' }}
            </template>
            <template v-slot:[`item.email`]="{ item }">
              {{ item.email || '--' }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              {{ item.status || '--' }}
            </template>
            <template v-slot:[`item.dataHoraTratamento`]="{ item }">
              {{ item.dataHoraTratamento | convertDatetimeFormat }}
            </template>
            <template
              v-slot:no-data
            >
              Nenhum resultado encontrado
            </template>
          </v-data-table>
        </div>

        <v-row justify="end" class="mt-2">
          <v-col sm="6" md="2">
            <div class="d-flex justify-center">
              <v-btn
                :data-test-id="'vincular-proposta'"
                :id="'vincular-proposta'"
                type="button"
                elevation="10"
                block
                color="primary"
                class="text-none mt-4"
                :disabled="disableBtnVinculoProposta"
                @click="vincularLead"
                :loading="loadingBtn"
              >
                Vincular Proposta
              </v-btn>
            </div>
          </v-col>
        </v-row>

      </v-container>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import _ from 'lodash';
import BaseTextField from "@/components/BaseTextField.vue";
import BaseAutocompleteField from '@/components/BaseAutocompleteField.vue';
import leadService from "@/services/leadService";

export default {
  components: {
    BaseTextField,
    BaseAutocompleteField,
  },
  props: {
    leads: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      filtros: {
        textSearch: '',
        status: '',
      },
      optionsStatus: [
        {
          titulo: 'Selecione',
          valor: '',
        },
        {
          titulo: 'Aguardando',
          valor: 'Aguardando',
        },
        {
          titulo: 'Expirado',
          valor: 'Expirado',
        },
        {
          titulo: 'Em tratamento',
          valor: 'Em tratamento',
        },
        {
          titulo: 'Finalizado',
          valor: 'Finalizado',
        },
        {
          titulo: 'Não trabalhado',
          valor: 'Não trabalhado',
        },
        {
          titulo: 'Proposta cancelada',
          valor: 'Proposta cancelada',
        },
      ],
      table: {
        singleSelect: true,
        selected: [],
        headers: [
          {
            text: 'Nome',
            align: 'start',
            value: 'nome',
            sortable: false,
          },
          {
            text: 'Celular',
            value: 'telefone',
            sortable: false,
          },
          {
            text: 'E-mail',
            value: 'email',
            sortable: false,
          },
          {
            text: 'Status',
            value: 'status',
            sortable: false,
          },
          {
            text: 'Última interação',
            value: 'dataHoraTratamento',
            sortable: false,
          },
        ],
        desserts: [],
      },
      loadingBtn: false,
      desserts: [],
    };
  },
  watch: {
    leads(newValue) {
      this.table.desserts = JSON.parse(JSON.stringify(newValue || []));
      this.desserts = JSON.parse(JSON.stringify(newValue || []));
    },
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
    disableBtnVinculoProposta() {
      return !(this.table.selected || []).length;
    },
    descricaoRegistrosEncontrados() {
      const qnt = this.desserts.length;
      if (qnt <= 0) {
        return '';
      }
      if (qnt > 1) {
        return `${qnt} leads encontrados`;
      }
      return `${qnt} lead encontrado`;
    },
  },
  created() {
    this.debounceInput = _.debounce(this.filtrarLeads, 1000);
  },
  methods: {
    // eslint-disable-next-line func-names
    filtrarLeads() {
      const textSearch = (this.filtros.textSearch || '').trim().toUpperCase();
      const statusSearch = (this.filtros.status || '').trim().toUpperCase();

      let desserts = JSON.parse(JSON.stringify(this.table.desserts || []));

      if (textSearch) {
        desserts = desserts.filter((item) => {
          const nome = (item.nome || '').trim().toUpperCase();
          const email = (item.email || '').trim().toUpperCase();
          const celular = (item.telefone ? `${item.telefone.ddd}${item.telefone.numero}` : '').trim().toUpperCase();
          return nome.includes(textSearch) || email.includes(textSearch) || celular.includes(textSearch);
        });
      }

      if (statusSearch) {
        desserts = desserts.filter((item) => {
          const status = (item.status || '').trim().toUpperCase();
          return status.includes(statusSearch);
        });
      }

      this.desserts = desserts;
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.resetDados();
      this.$emit('recarregardados');
    },
    resetDados() {
      this.filtros.textSearch = '';
      this.filtros.status = '';
      this.table.selected = [];
      this.table.desserts = [];
    },
    async vincularLead() {
      if (!this.table.selected.length) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Selecione um lead para vincular',
        });
        return;
      }
      try {
        const [lead] = this.table.selected;
        this.loadingBtn = true;
        await leadService.vincularLeadAProposta(lead.id, this.propostaState.guid);
        this.dialog = false;
        this.resetDados();
        this.$emit('transmitir');
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || 'Erro ao vincular lead',
        });
      } finally {
        this.loadingBtn = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
