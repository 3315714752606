var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("pergunta", { ref: "pergunta", on: { sim: _vm.sim, nao: _vm.nao } }),
      _c("vinculo-lead", {
        ref: "vinculoLead",
        attrs: { leads: _vm.leads },
        on: {
          recarregardados: _vm.recarregarDados,
          transmitir: _vm.transmitir,
        },
      }),
      _c("origem-venda", {
        ref: "origemVenda",
        attrs: { origensVenda: _vm.origensVenda },
        on: {
          recarregardados: _vm.recarregarDados,
          transmitir: _vm.transmitir,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }