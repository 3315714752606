var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closedialog")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Dados Portabilidade ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Portabilidade dos beneficiários ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _vm.getBeneficiarios.length > 0
        ? _c(
            "v-container",
            { staticClass: "pa-5 pt-0" },
            [
              _c(
                "v-expansion-panels",
                {
                  staticClass: "mb-12",
                  attrs: { multiple: "" },
                  model: {
                    value: _vm.panel,
                    callback: function ($$v) {
                      _vm.panel = $$v
                    },
                    expression: "panel",
                  },
                },
                _vm._l(_vm.getBeneficiarios, function (item, i) {
                  return _c(
                    "v-expansion-panel",
                    { key: i },
                    [
                      _c("v-expansion-panel-header", [
                        _c(
                          "h1",
                          {
                            staticClass:
                              "title font-weight-bold mb-1 primary--text",
                            staticStyle: { "line-height": "1" },
                          },
                          [_vm._v(" " + _vm._s(item.nome) + " ")]
                        ),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("v-divider", { staticClass: "mb-4" }),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Portabilidade")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item && item.portabilidade != undefined
                                          ? item.portabilidade
                                            ? "Sim"
                                            : "Não"
                                          : _vm.propostaState
                                              .possuiPortabilidade
                                          ? "Sim"
                                          : "Não"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Segmentação Anterior")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (item && item.segmentacaoAnterior) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _c("v-container", { staticClass: "pt-2 text-center h-100" }, [
            _vm._v(" Nenhum beneficiário cadastrado "),
          ]),
      _vm.showEditButton
        ? _c(
            "v-footer",
            { attrs: { fixed: "" } },
            [
              _c(
                "v-col",
                { staticClass: "text-center pa-1", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secundaryBtn--text",
                      attrs: { large: "", color: "primary" },
                      on: { click: _vm.edit },
                    },
                    [_vm._v(" Editar dados ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }