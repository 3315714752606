<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('closedialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1" style="line-height: 1">
              Adendo
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Relação de dados alterados
            </h3>
          </div>
        </v-toolbar-title>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>

    <v-container class="pa-5 pt-2 mb-10">
      <v-row no-gutters>
        <v-col cols="12" class="text-center mb-2">
          {{ adendoState.length }} alteração(ões) registrada(s)
        </v-col>
      </v-row>
      <v-card v-for="(item, i) in adendo" :key="i" class="mb-4">
        <v-card-text>
          <v-row>
            <v-col cols="3" lg="1" class="text-center">
              <v-icon color="primary" size="55">mdi-account-circle-outline</v-icon>
            </v-col>
            <v-col cols="9" lg="11">
              <span class="font-weight-bold primary--text">
                {{ item.beneficiario }}
              </span><br>
              <span class="primary--text">
                {{ item.dados.length }} alteração(ões)
              </span><br>
            </v-col>
          </v-row>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left subtitle-1 font-weight-bold">
                    Campo
                  </th>
                  <th class="text-left subtitle-1 font-weight-bold">
                    De
                  </th>
                  <th class="text-left subtitle-1 font-weight-bold">
                    Para
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(dado, d) in item.dados" :key="d">
                  <td>{{ dado.descricao }}</td>
                  <td>{{formatarValoresNovos(dado.valorAnterior, dado.campo)}}</td>
                  <td v-if="dado.campo === 'documento'">
                    <v-icon @click="zoom(dado.link, dado.valorNovo, false, dado.campo)" :size="40" class="anexos-image-pdf-icon"
                      v-if="checkIfDocumentIsPdf(dado.link)">mdi-file-pdf-box</v-icon>
                    <v-img
                      v-else
                      class="anexos-image"
                      :height="80"
                      :width="80"
                      position="top"
                      v-bind="$attrs"
                      :src="dado.link"
                      @click="zoom(dado.link, dado.valorNovo, false, dado.campo)"
                      alt
                  ></v-img>
                  </td>
                  <td v-else>{{formatarValoresNovos(dado.valorNovo, dado.campo)}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-container>

    <v-footer fixed v-if="showBotao">
      <v-col class="text-center pa-1" cols="12" >
        <v-btn large v-if="botaoInfos.nome" color="primary" class="secundaryBtn--text" @click="botaoInfos.acao"  :disabled="botaoInfos.segundosParaHabilitar > 0">
         {{ botaoInfos.segundosParaHabilitar > 0 && !loading ? `${botaoInfos.nome} ${botaoInfos.segundosParaHabilitar}` : `${botaoInfos.nome}`}}
        </v-btn>
      </v-col>
    </v-footer>

    <v-dialog v-model="preview.show">
      <v-card tile dark color="black">
        <v-app-bar color="black" dark fixed>
          <v-container class="d-flex align-center pa-0 pa-md-4">
            <v-btn class="mr-3" small icon dark @click="preview.show = false">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>

            <v-toolbar-title class="ml-2">Documento</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-container>
        </v-app-bar>
        <div style="height: 70px"></div>
        <v-container class="d-flex justify-center pa-4">
          <v-img max-width="800" width="100%" :src="preview.file"></v-img>
        </v-container>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import propostaService from '@/services/propostaService';
import documentoService from '@/services/documentoService';
import { mapActions, mapState } from 'vuex';
import moment, { isMoment } from 'moment';

export default {
  props: {
    validarEnvioAssAdendo: Function,
  },
  data() {
    return {
      loading: false,
      adendo: [],
      botaoInfos: {
        nome: '',
        segundosParaHabilitar: 0,
        acao: () => {},
      },
      preview: {
        file: null,
        show: false,
      },
    };
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
      adendoState: (state) => state.cadastroProposta.adendo,
    }),
    showBotao() {
      return this.propostaState.etapa !== 'adendo_assinado';
    },
  },
  mounted() {
    this.agruparAdendoPorBeneficiario();
    this.getInfosBotao();
  },
  methods: {
    agruparAdendoPorBeneficiario() {
      const beneficiarios = this.adendoState.map(({ beneficiario }) => beneficiario);
      const distBeneficiarios = beneficiarios.filter((value, index, self) => self.indexOf(value) === index);
      distBeneficiarios.forEach(async (item, i) => {
        const dados = this.adendoState.filter(({ beneficiario }) => beneficiario === item);

        this.adendo.push({ beneficiario: item, dados });
      });
    },
    async enviarAssinaturaAdendo() {
      if (this.botaoInfos.segundosParaHabilitar <= 0) {
        try {
          this.loading = true;
          this.botaoInfos.nome = "Enviando...";
          this.desabilitaPor(this.botaoInfos, 30);
          const { guid: guidProposta } = this.propostaState;
          await propostaService.enviarAssinaturaAdendo({ guidProposta });
          await propostaService.atualizarEtapa({ guidProposta, etapa: 'aguardando_assinatura_adendo' });
          this.$emit('closedialog');
        } catch (error) {
          this.$root.$snackBar.open({
            color: 'error',
            message: 'Erro ao enviar para assinatura de adendo, tente novamente mais tarde',
          });
        } finally {
          this.botaoInfos.nome = "Enviar para assinatura";
          this.loading = false;
        }
      }
    },
    desabilitaPor(botao, segundos) {
      botao.segundosParaHabilitar = segundos;
      setTimeout(() => {
        botao.segundosParaHabilitar -= 1;
        if (botao.segundosParaHabilitar > 0) { this.desabilitaPor(botao, segundos - 1); }
      }, 1000);
    },
    getInfosBotao() {
      this.botaoInfos.nome = "enviar para assinatura";
      this.botaoInfos.acao = async () => {
        const permiteEnviarAssAdendo = await this.validarEnvioAssAdendo();
        if (permiteEnviarAssAdendo) {
          this.enviarAssinaturaAdendo();
          return;
        }
        this.$root.$snackBar.open({
          color: "error",
          message: "Cliente já assinou o adendo",
        });
        this.$emit('closedialog');
      };
    },
    formatarValoresNovos(valor, campo) {
      const camposCurrency = ["valorTotal", "valorTotalSemDesconto"];
      if (camposCurrency.includes(campo)) {
        return this.$options.filters.currency(valor);
      }

      if (moment(valor, 'YYYY-MM-DD', true).isValid()) {
        return moment(valor).format('DD/MM/YYYY');
      }

      // solução temporária para não causar efeito colateral no backend
      if (valor === 'true' || valor === 'false') {
        valor = valor === 'true' ? "SIM" : "NÃO";
      }

      return valor;
    },
    zoom(file, guid, habilitadoRemove, origem) {
      if (file.indexOf('pdf') > 0) {
        window.open(file);
      } else {
        this.preview.file = file;
        this.preview.guid = guid;
        this.preview.show = true;
        this.preview.habilitadoRemove = habilitadoRemove;
        this.preview.origem = origem;
      }
    },
    checkIfDocumentIsPdf(link) {
      const linkSplit = link.split('.')
      if(!linkSplit || linkSplit.length === 0) return false

      const tipoAssinatura = linkSplit[linkSplit.length - 1]
      if(!tipoAssinatura || tipoAssinatura.length === 0) return false

      const tipo = tipoAssinatura.split('?')
      if(!tipo || tipo.length === 0) return false

      return tipo[0] === 'pdf'
    }
  },
};
</script>

<style lang="scss" scoped>

  .header-height {
    padding: 20px;
    height: 70px;
  }

  .border-top {
    border-top-style: solid;
    border-top-width: thin;
    border-top-color: #E0E0E0;
  }

</style>
