var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c(
                  "h2",
                  {
                    staticClass:
                      "primary--text mb-2 subtitle-1 font-weight-bold",
                  },
                  [_vm._v(" Atenção! ")]
                ),
              ]),
              _c("v-card-text", [
                _vm._v(" Deseja vincular a proposta à um lead existente? "),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("nao")
                        },
                      },
                    },
                    [_vm._v(" Não ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "", type: "submit" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("sim")
                        },
                      },
                    },
                    [_vm._v(" Sim ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }