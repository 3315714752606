var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closedialog")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Pendências ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Proposta devolvida ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-2" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "text-center mb-2", attrs: { cols: "12" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.pendenciasState.length) +
                      " pendência(s) registrada(s) "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._l(_vm.pendenciasState, function (item, i) {
            return _c(
              "div",
              { key: i, staticClass: "d-flex border-top pt-4 pb-4" },
              [
                _c("v-icon", { attrs: { color: "amber", large: "" } }, [
                  _vm._v("mdi-alert-circle"),
                ]),
                _c("span", { staticClass: "ml-5 pt-2 primary--text" }, [
                  _vm._v(_vm._s(item.descricao)),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }