var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closedialog")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Ficha de Filiação ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Informações sobre a ficha de filiação ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "d-flex justify-center mt-10 pa-10" },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-container",
                { staticClass: "pa-5 pt-0" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mb-2 mt-2", attrs: { cols: "12" } },
                        [
                          _c("b", [
                            _vm._v("Aceitou preencher a ficha digital?"),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.propostaState.dadosComplementaresFiliacao &&
                                  _vm.propostaState.dadosComplementaresFiliacao
                                    .aceitaFiliacao == true
                                  ? "Sim"
                                  : _vm.propostaState
                                      .dadosComplementaresFiliacao &&
                                    _vm.propostaState
                                      .dadosComplementaresFiliacao
                                      .aceitaFiliacao == false
                                  ? "Não"
                                  : "--"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "mb-4 mt-2" }),
                  _vm.entidadeComFiliacaoApiPropria && _vm.msgTaxa
                    ? _c(
                        "v-row",
                        { staticClass: "mb-2", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Taxa de Filiação")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" " + _vm._s(_vm.msgTaxa) + " "),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { staticClass: "mb-2", attrs: { "no-gutters": "" } },
                    _vm._l(_vm.campos, function (c, index) {
                      return _c(
                        "v-col",
                        {
                          key: index,
                          staticClass: "pb-2",
                          attrs: { cols: "6" },
                        },
                        [
                          _c("b", [_vm._v(_vm._s(c.titulo))]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            " " + _vm._s(c.valor != "" ? c.valor : "--") + " "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.showEditButton
                ? _c(
                    "v-footer",
                    { attrs: { fixed: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center pa-1",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "secundaryBtn--text",
                              attrs: { large: "", color: "primary" },
                              on: { click: _vm.edit },
                            },
                            [_vm._v(" Editar dados ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }