<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="onClickAction">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1" style="line-height: 1">
              Documentos
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Dados contratação
            </h3>
          </div>
        </v-toolbar-title>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>
    <div v-if="loadingDocs" class="d-flex justify-center mt-10 pa-10">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <v-container v-else-if="documentos.length > 0" class="pa-5 pt-0">
      <v-row>
        <v-col cols="6" md="4" class="pa-1" v-for="(documento, index) in documentos" :key="index">
          <base-card-document
            :type="formataTipoBeneficiario(documento.tipoBeneficiario)"
            :name="documento.beneficiario && documento.beneficiario.nome | firstName"
            :gender="documento.beneficiario && documento.beneficiario.sexo"
            :age="documento.beneficiario && documento.beneficiario.dataNascimento | getAge"
            :status="getStatus(index)"
            @click="openDocumentos(index, documento.tipoBeneficiario )"
            :dark="documento.tipoBeneficiario === 'TITULAR' ? true : false"
          ></base-card-document>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      :retain-focus="false"
      no-click-animation
      v-model="dialogDoc"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="paramEnviar">
        <component
          ref="component"
          :is="component"
          @closedialog="dialogDoc = false"
          :param.sync="paramEnviar"
          @update="carregarDocumentos"
        ></component>
      </v-card>
    </v-dialog>

    <v-footer fixed>
      <v-col class="text-center pa-1" cols="12">
        <v-btn large color="primary" class="secundaryBtn--text" @click="onClickAction">
          Salvar
        </v-btn>
      </v-col>
    </v-footer>

  </div>
</template>

<script>
import BaseCardDocument from '@/components/BaseCardDocument.vue';
import ContentDocuments from '@/components/documentos/ContentDocuments.vue';
import documentoService from '@/services/documentoService';

import { mapState, mapActions } from 'vuex';
import moment from 'moment';

export default {
  components: { BaseCardDocument },
  props: {
    showBotao: Boolean,
    anexarArquivos: Boolean,
    excluirArquivos: Boolean,
  },
  data() {
    return {
      ativo: {},
      beneficiarios: [],
      dialogDoc: false,
      paramEnviar: {},
      component: ContentDocuments,
      loadingDocs: false,
      documentos: [],
      subtitulo: '',
      componentKey: 0,
    };
  },
  created() {
    this.agruparBeneficiarios();
  },
  async mounted() {
    await this.carregarDocumentos();
  },
  watch: {
    immediate: true,
    deep: true,
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
      dadosTitularState: (state) => state.cadastroProposta.titular,
    }),
  },
  methods: {
    async carregarDocumentos(update) {
      try {
        this.loadingDocs = true;
        const { guid: guidProposta } = this.propostaState;
        const { data } = await documentoService.getDocumentosPorParticipante({
          guidProposta,
        });

        await this.validaHabilitadoRemoveDocumentos(data);

        this.agruparDocumentos(data, update);
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao buscar documentos, tente novamente mais tarde',
        });
      } finally {
        this.loadingDocs = false;
      }
    },
    validaHabilitadoRemoveDocumentos(dados) {
      const arquivos = [];

      for (let d = 0; d < dados.length; d++) {
        const dado = dados[d];

        for (let td = 0; td < dado.tiposDocumentos.length; td++) {
          const tipoDocumento = dado.tiposDocumentos[td];

          if (tipoDocumento.habilitadoUpload && tipoDocumento.visivel && tipoDocumento.arquivos.length > 0) {
            arquivos.push(...tipoDocumento.arquivos);
          }
        }
      }

      arquivos.map((a) => {
        a.habilitadoRemove = this.propostaState.ultimaAssinatura ? moment(a.dataCriacao, 'YYYY-MM-DD HH:mm:ss').valueOf() > this.propostaState.ultimaAssinatura : a.habilitadoRemove;
        return a;
      });
    },
    agruparBeneficiarios() {
      this.beneficiarios.push({
        guid: this.dadosTitularState.guid,
        nome: this.dadosTitularState.nome,
        sexo: this.dadosTitularState.sexo,
        dataNascimento: this.dadosTitularState.dataNascimento,
      });
      if (this.propostaState.responsavelLegal && Object.keys(this.propostaState.responsavelLegal).length) {
        this.beneficiarios.push({
          guid: this.propostaState.responsavelLegal.guid,
          nome: this.propostaState.responsavelLegal.nome,
          sexo: this.propostaState.responsavelLegal.sexo,
          dataNascimento: this.propostaState.responsavelLegal.dataNascimento,
        });
      }
      this.propostaState.dependentes.forEach((dep) => {
        this.beneficiarios.push({
          guid: dep.guid,
          nome: dep.nome,
          sexo: dep.sexo,
          dataNascimento: dep.dataNascimento,
        });
      });
    },
    agruparDocumentos(data, update) {
      this.documentos = [];
      data.forEach((item, i) => {
        const [beneficiario] = this.beneficiarios.filter((ben) => ben.guid === item.guid);
        const retorno = {
          guid: item.guid,
          tipoBeneficiario: item.tipoBeneficiario,
          parentesco: item.parentesco,
          tiposDocumentos: [],
          beneficiario,
        };
        const { tiposDocumentos } = item;
        const grupos = tiposDocumentos.map(({ grupo }) => grupo);
        const distinctGrupos = grupos.filter((value, index, self) => self.indexOf(value) === index);
        distinctGrupos.forEach((itemDg) => {
          const docs = tiposDocumentos.filter(({ grupo, visivel }) => grupo === itemDg && visivel);
          const { habilitadoUpload } = tiposDocumentos.find((doc) => doc.grupo === itemDg);
          if (docs && docs.length > 0) {
            const totalDocumentos = docs.length;
            const arquivosTotal = docs.filter((doc) => doc.arquivos.length > 0);
            let preenchido = false;
            if (arquivosTotal.length > 0) preenchido = true;
            if (itemDg !== "Contrato") {
              retorno.tiposDocumentos.push({
                grupo: itemDg,
                documentos: docs,
                preenchido,
                habilitadoUpload,
              });
            }
            // COM NOVA REGRA, PRECISA APENAS TER UM DOC DE CADA TIPO PRA CONSIDERAR PREENCHIDO. (DEIXAR COMENTADO)
            // if ((itemDg === 'Elegibilidade' || itemDg === 'Dados Responsável') && arquivosTotal.length > 0) preenchido = true;
            // else if (arquivosTotal.length === totalDocumentos) preenchido = true;
          }
        });
        this.documentos.push(retorno);
      });
      if (update) {
        this.openDocumentos(this.ativo.item, this.ativo.tipo);
      }
    },

    openDocumentos(item, tipo) {
      const dadosDocumentos = this.documentos[item];
      this.paramEnviar = {
        beneficiario: dadosDocumentos.beneficiario,
        dadosDocumentos,
        guidProposta: this.propostaState.guid,
        etapaProposta: this.propostaState.etapa,
        showBotao: this.showBotao,
        excluirArquivos: this.excluirArquivos,
        anexarArquivos: this.anexarArquivos,
      };
      this.dialogDoc = true;
      this.ativo.item = item;
      this.ativo.tipo = tipo;
    },
    age(value) {
      return this.$options.filters.getAge(value);
    },
    getNome(value) {
      return this.$options.filters.firstName(value);
    },
    formataNascimento(value) {
      return this.$options.filters.convertDateOutput(value);
    },
    getStatus(index) {
      const docs = this.documentos[index];
      const docsObrigatorios = docs.tiposDocumentos.filter((doc) => doc.grupo !== "Outros");
      const total = docsObrigatorios.filter((doc) => doc.preenchido);
      return total.length === docsObrigatorios.length ? 'preenchido' : 'pendente';
    },
    formataTipoBeneficiario(tipo) {
      tipo = tipo.toLowerCase();
      if (tipo === 'responsavel_legal') return 'responsável legal';
      return tipo;
    },
    onClickAction() {
      this.$emit("recarregardocumentos");
      this.$emit("closedialog");
    },
  },
};
</script>

<style lang="scss" scoped>

  .header-height {
    padding: 20px;
    height: 70px;
  }

</style>
