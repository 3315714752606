var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closedialog")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Dados Dependentes ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Relação de dependentes ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _vm.propostaState.dependentes.length > 0
        ? _c(
            "v-container",
            { staticClass: "pa-5 pt-0" },
            [
              _c(
                "v-expansion-panels",
                {
                  staticClass: "mb-12",
                  attrs: { multiple: "" },
                  model: {
                    value: _vm.panel,
                    callback: function ($$v) {
                      _vm.panel = $$v
                    },
                    expression: "panel",
                  },
                },
                _vm._l(_vm.propostaState.dependentes, function (item, i) {
                  return _c(
                    "v-expansion-panel",
                    { key: i },
                    [
                      _c("v-expansion-panel-header", [
                        _c(
                          "h1",
                          {
                            staticClass:
                              "title font-weight-bold mb-1 primary--text",
                            staticStyle: { "line-height": "1" },
                          },
                          [_vm._v(" Dependente " + _vm._s(i + 1) + " ")]
                        ),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("v-divider", { staticClass: "mb-4" }),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "12" } },
                                [
                                  _c("b", [_vm._v("Nome")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s((item && item.nome) || "--") +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("CPF")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s((item && item.cpf) || "--") +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Data de Nascimento")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("convertDateOutput")(
                                          item && item.dataNascimento
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "12" } },
                                [
                                  _c("b", [_vm._v("Nome da Mãe")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s((item && item.nomeMae) || "--") +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Sexo")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s((item && item.sexo) || "--") +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Estado Civil")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (item && item.estadoCivil) || "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Grau de Parentesco")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (item && item.parentesco) || "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              item.dataEvento
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c("b", [_vm._v("Data do Evento")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("convertDateOutput")(
                                              item && item.dataEvento
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("PIS/PASEP")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s((item && item.pisPasep) || "--") +
                                      " "
                                  ),
                                ]
                              ),
                              item.declaracaoNascidoVivo
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v("Declaração Nascido Vivo"),
                                      ]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            (item &&
                                              item.declaracaoNascidoVivo) ||
                                              "--"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.cns
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v("Cartão Nacional Saúde"),
                                      ]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s((item && item.cns) || "--") +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.propostaState.plano &&
                              _vm.propostaState.plano.operadora.toLowerCase() ===
                                "unimed volta redonda"
                                ? _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-2",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("b", [_vm._v("Identidade")]),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                (item && item.rg) || "--"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-2",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("b", [_vm._v("Orgão Emissor")]),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                (item && item.rgOrgaoEmissor) ||
                                                  "--"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-2",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("b", [_vm._v("UF Emissor")]),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                (item &&
                                                  item.rgOrgaoEmissorUf) ||
                                                  "--"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      item && item.rgDataExpedicao
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass: "mb-2",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c("b", [
                                                _vm._v("Data Expedição"),
                                              ]),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("convertDateOutput")(
                                                      item.rgDataExpedicao
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "v-col",
                                            {
                                              staticClass: "mb-2",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c("b", [
                                                _vm._v("Data Expedição"),
                                              ]),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" " + _vm._s("--") + " "),
                                            ]
                                          ),
                                    ],
                                    1
                                  )
                                : [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c("b", [_vm._v("Identidade")]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s((item && item.rg) || "--") +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c("b", [_vm._v("Data Expedição")]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("convertDateOutput")(
                                                item.rgDataExpedicao
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                            ],
                            2
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Portabilidade")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item && item.portabilidade != undefined
                                          ? item.portabilidade
                                            ? "Sim"
                                            : "Não"
                                          : _vm.propostaState
                                              .possuiPortabilidade
                                          ? "Sim"
                                          : "Não"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Segmentação Anterior")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (item && item.segmentacaoAnterior) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Redução de carência")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item && item.possuiPlano
                                          ? "Sim"
                                          : "Não" || "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm.propostaState.plano &&
                              _vm.propostaState.plano.operadora.toLowerCase() ===
                                "unimed bh"
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c("b", [_vm._v("Carteirinha anterior")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.campoCarteirinha || "--"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _c("v-container", { staticClass: "pt-2 text-center h-100" }, [
            _vm._v(" Nenhum dependente cadastrado "),
          ]),
      _vm.showEditButton
        ? _c(
            "v-footer",
            { attrs: { fixed: "" } },
            [
              _c(
                "v-col",
                { staticClass: "text-center pa-1", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secundaryBtn--text",
                      attrs: { large: "", color: "primary" },
                      on: { click: _vm.edit },
                    },
                    [_vm._v(" Editar dados ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }