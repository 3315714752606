var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: { click: _vm.onClickAction },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Documentos ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Dados contratação ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _vm.loadingDocs
        ? _c(
            "div",
            { staticClass: "d-flex justify-center mt-10 pa-10" },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : _vm.documentos.length > 0
        ? _c(
            "v-container",
            { staticClass: "pa-5 pt-0" },
            [
              _c(
                "v-row",
                _vm._l(_vm.documentos, function (documento, index) {
                  return _c(
                    "v-col",
                    {
                      key: index,
                      staticClass: "pa-1",
                      attrs: { cols: "6", md: "4" },
                    },
                    [
                      _c("base-card-document", {
                        attrs: {
                          type: _vm.formataTipoBeneficiario(
                            documento.tipoBeneficiario
                          ),
                          name: _vm._f("firstName")(
                            documento.beneficiario &&
                              documento.beneficiario.nome
                          ),
                          gender:
                            documento.beneficiario &&
                            documento.beneficiario.sexo,
                          age: _vm._f("getAge")(
                            documento.beneficiario &&
                              documento.beneficiario.dataNascimento
                          ),
                          status: _vm.getStatus(index),
                          dark:
                            documento.tipoBeneficiario === "TITULAR"
                              ? true
                              : false,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openDocumentos(
                              index,
                              documento.tipoBeneficiario
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            "retain-focus": false,
            "no-click-animation": "",
            persistent: "",
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition",
          },
          model: {
            value: _vm.dialogDoc,
            callback: function ($$v) {
              _vm.dialogDoc = $$v
            },
            expression: "dialogDoc",
          },
        },
        [
          _vm.paramEnviar
            ? _c(
                "v-card",
                [
                  _c(_vm.component, {
                    ref: "component",
                    tag: "component",
                    attrs: { param: _vm.paramEnviar },
                    on: {
                      closedialog: function ($event) {
                        _vm.dialogDoc = false
                      },
                      "update:param": function ($event) {
                        _vm.paramEnviar = $event
                      },
                      update: _vm.carregarDocumentos,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-footer",
        { attrs: { fixed: "" } },
        [
          _c(
            "v-col",
            { staticClass: "text-center pa-1", attrs: { cols: "12" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "secundaryBtn--text",
                  attrs: { large: "", color: "primary" },
                  on: { click: _vm.onClickAction },
                },
                [_vm._v(" Salvar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }