<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('closedialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1" style="line-height: 1">
              Pendências
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Proposta devolvida
            </h3>
          </div>
        </v-toolbar-title>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>

    <v-container class="pa-5 pt-2">
      <v-row no-gutters>
        <v-col cols="12" class="text-center mb-2">
          {{ pendenciasState.length }} pendência(s) registrada(s)
        </v-col>
      </v-row>
      <div class="d-flex border-top pt-4 pb-4" v-for="(item, i) in pendenciasState" :key="i">
        <v-icon color="amber" large>mdi-alert-circle</v-icon>
        <span class="ml-5 pt-2 primary--text">{{ item.descricao }}</span>
      </div>
    </v-container>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    param: Array,
  },
  computed: {
    ...mapState({
      pendenciasState: (state) => state.cadastroProposta.pendencias,
    }),
  },
};
</script>

<style lang="scss" scoped>

  .header-height {
    padding: 20px;
    height: 70px;
  }

  .border-top {
    border-top-style: solid;
    border-top-width: thin;
    border-top-color: #E0E0E0;
  }

</style>
