var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-8 pb-8" },
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closedialog")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Dados Financeiros ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Informações financeiras ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-0" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { staticClass: "mb-2 mt-2", attrs: { cols: "12" } }, [
                _c("b", [_vm._v("Forma de pagamento")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      (_vm.propostaState.pagamento &&
                      _vm.propostaState.pagamento.forma &&
                      _vm.propostaState.pagamento.forma.toUpperCase() ===
                        "BOLETO"
                        ? "Boleto Digital"
                        : "Débito Automático") || "--"
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "h1",
            {
              staticClass: "title font-weight-bold mb-1 mt-4 primary--text",
              staticStyle: { "line-height": "1" },
            },
            [_vm._v(" Dados de Débito Automático ")]
          ),
          _c("v-divider", { staticClass: "mb-2 mt-2" }),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { staticClass: "mb-2 mt-2", attrs: { cols: "12" } }, [
                _c("b", [_vm._v("Banco")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      (_vm.propostaState.pagamento &&
                        _vm.propostaState.pagamento.banco) ||
                        "--"
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { staticClass: "mb-2 mt-2", attrs: { cols: "6" } }, [
                _c("b", [_vm._v("Agência")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      (_vm.propostaState.pagamento &&
                        _vm.propostaState.pagamento.agencia) ||
                        "--"
                    ) +
                    " "
                ),
              ]),
              _c("v-col", { staticClass: "mb-2 mt-2", attrs: { cols: "6" } }, [
                _c("b", [_vm._v("Conta")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      (_vm.propostaState.pagamento &&
                        _vm.propostaState.pagamento.contaCorrente) ||
                        "--"
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "h1",
            {
              staticClass: "title font-weight-bold mb-1 mt-4 primary--text",
              staticStyle: { "line-height": "1" },
            },
            [_vm._v(" Dados de Reembolso ")]
          ),
          _c("v-divider", { staticClass: "mb-2 mt-2" }),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { staticClass: "mb-2 mt-2", attrs: { cols: "12" } }, [
                _c("b", [_vm._v("Banco")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      (_vm.propostaState.dadosParaReembolso &&
                        _vm.propostaState.dadosParaReembolso.banco) ||
                        "--"
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { staticClass: "mb-2 mt-2", attrs: { cols: "6" } }, [
                _c("b", [_vm._v("Agência")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      (_vm.propostaState.dadosParaReembolso &&
                        _vm.propostaState.dadosParaReembolso.agencia) ||
                        "--"
                    ) +
                    " "
                ),
              ]),
              _c("v-col", { staticClass: "mb-2 mt-2", attrs: { cols: "6" } }, [
                _c("b", [_vm._v("Conta")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      (_vm.propostaState.dadosParaReembolso &&
                        _vm.propostaState.dadosParaReembolso.contaCorrente) ||
                        "--"
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _vm.propostaState.tipoTaxaAngariacao
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-2 mt-2", attrs: { cols: "12" } },
                    [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "title font-weight-bold mb-1 mt-4 primary--text",
                          staticStyle: { "line-height": "1" },
                        },
                        [_vm._v(" Tipo de Taxa de Angariação ")]
                      ),
                      _c("v-divider", { staticClass: "mb-2 mt-2" }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2 mt-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Tipo")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tipoTaxaAngariacao(
                                      _vm.propostaState.tipoTaxaAngariacao
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _vm.propostaState.valorTaxaAngariacao
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "mb-2 mt-2",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c("b", [_vm._v("Taxa de adesão")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.propostaState.valorTaxaAngariacao
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.propostaState.isentoPor &&
                          _vm.propostaState.tipoTaxaAngariacao === "ISENCAO"
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "mb-2 mt-2",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c("b", [_vm._v("Justificativa")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.propostaState.isentoPor) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm.propostaState.justificativaTaxaAngariacao &&
                              _vm.propostaState.tipoTaxaAngariacao === "ISENCAO"
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "mb-2 mt-2",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c("b", [_vm._v("Justificativa")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.propostaState
                                          .justificativaTaxaAngariacao
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.taxaAngariacao.status
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "mb-2 mt-2",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c("b", [_vm._v("Status")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm.propostaState.tipoTaxaAngariacao ===
                                  "ISENCAO"
                                    ? _c("span", [_vm._v(" -- ")])
                                    : _c(
                                        "span",
                                        { staticClass: "text-uppercase" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.taxaAngariacao.status
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                  _c("span"),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showEditButton
        ? _c(
            "v-footer",
            { attrs: { fixed: "" } },
            [
              _c(
                "v-col",
                { staticClass: "text-center pa-1", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secundaryBtn--text",
                      attrs: { large: "", color: "primary" },
                      on: { click: _vm.edit },
                    },
                    [_vm._v(" Editar dados ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }