var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "primary pa-2" },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("div", { staticClass: "white--text" }, [
                      _c(
                        "h1",
                        {
                          staticClass: "display-1 font-weight-regular mb-2",
                          staticStyle: { "line-height": "1" },
                        },
                        [_vm._v(" Tudo certo! ")]
                      ),
                      _c(
                        "h3",
                        {
                          staticClass: "subtitle-1 ma-0",
                          staticStyle: { "line-height": "1" },
                        },
                        [_vm._v(" A proposta foi transmitida com sucesso. ")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-container", { staticClass: "pa-5 pb-5 pt-0" }, [
        _c(
          "div",
          { staticClass: "text-center mt-5" },
          [
            _c(
              "v-icon",
              {
                staticStyle: { "font-size": "300px" },
                attrs: { color: "primary" },
              },
              [_vm._v("mdi-check-circle")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-footer",
        { attrs: { fixed: "" } },
        [
          _c(
            "v-col",
            { staticClass: "text-center pa-1", attrs: { cols: "12" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { large: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("concluir")
                    },
                  },
                },
                [_vm._v(" Concluir ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }