<template>
  <div>
    <div class="primary pa-2">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <div class="white--text">
              <h1 class="display-1 font-weight-regular mb-2" style="line-height: 1">
                Quase lá!
              </h1>
              <h3 class="subtitle-1 ma-0" style="line-height: 1">
                Revise os dados para concluir a transmissão.
              </h3>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container class="pa-5 pb-5 pt-0">
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h1 class="title font-weight-regular mt-4 mb-1 primary--text" style="line-height: 1">
              Pendências ({{ pendenciasPreenchimento.length }})
            </h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex border-top pt-4 pb-4" v-for="(item, i) in pendenciasPreenchimento" :key="i">
              <v-icon color="amber" large>mdi-alert-circle</v-icon>
              <span class="ml-5 pt-2 primary--text">{{ item.mensagem }}</span>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h1 class="title font-weight-regular mt-4 mb-1 primary--text" style="line-height: 1">
              Erros ({{ erros.length }})
            </h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex border-top pt-4 pb-4" v-for="(item, i) in erros" :key="i">
              <v-icon color="red darken-4" large>mdi-close-circle</v-icon>
              <span class="ml-5 pt-2 primary--text">{{ item.mensagem }}</span>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>

  </div>
</template>

<script>

export default {
  props: {
    pendenciasPreenchimento: Array,
    erros: Array,
  },
  data() {
    return {
      panel: [],
      itens: 2,
    };
  },
  mounted() {
    this.expandirTodos();
  },
  methods: {
    expandirTodos() {
      this.panel = [...Array(this.itens).keys()].map((k, i) => i);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
