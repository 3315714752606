<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('closedialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1" style="line-height: 1">
              Dados titular
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Informações pessoais e endereço
            </h3>
          </div>
        </v-toolbar-title>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>

    <v-container class="pa-5 pb-5 pt-0 elevation-0">
      <v-expansion-panels v-model="panel" multiple class="pb-5 elevation-0">
        <v-expansion-panel class="elevation-0">
          <v-expansion-panel-header>
            <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">
              Dados básicos
            </h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="mb-4"></v-divider>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Nome</b> <br>
                {{ propostaState.titular && propostaState.titular.nome || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Email</b> <br>
                {{ propostaState.titular && propostaState.titular.email || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>CPF</b> <br>
                {{ propostaState.titular && propostaState.titular.cpf || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Data de Nascimento</b> <br>
                {{ propostaState.titular && propostaState.titular.dataNascimento | convertDateOutput }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Nome da Mãe</b> <br>
                {{ propostaState.titular && propostaState.titular.nomeMae || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Sexo</b> <br>
                {{ propostaState.titular && propostaState.titular.sexo || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Estado Civil</b> <br>
                {{ propostaState.titular && propostaState.titular.estadoCivil || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Telefone Principal</b> <br>
                {{ celularTitular && celularTitular.numero || '--' }}
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Telefone Opcional</b> <br>
                <div v-for="(item, index) in telefonesOpcionais" :key="index">
                  <v-row class="align-row pl-3 pt-3 mb-1">
                    {{ telefonesOpcionais && telefonesOpcionais[index].numero || '--'}}
                  </v-row>
                </div>
              </v-col>
            </v-row>

            <v-row no-gutters v-if="propostaState.titular && propostaState.titular.cns">
              <v-col cols="12" class="mb-2">
                <b>Cartão Nacional de Saúde</b> <br>
                {{ propostaState.titular && propostaState.titular.cns || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters v-if="propostaState.plano && propostaState.plano.operadora.toLowerCase() === 'unimed volta redonda'">
              <v-col cols="6" class="mb-2">
                <b>Identidade</b> <br>
                {{ propostaState.titular && propostaState.titular.rg || '--' }}
              </v-col>

               <!-- Campos exclusivos para unimed volta redonda -->

              <v-col cols="6" class="mb-2">
                <b>Orgão Emissor</b> <br>
                {{ propostaState.titular && propostaState.titular.rgOrgaoEmissor || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>UF Emissor</b> <br>
                {{ propostaState.titular && propostaState.titular.rgOrgaoEmissorUf || '--' }}
              </v-col>
              <v-col v-if="propostaState.titular && propostaState.titular.rgDataExpedicao" cols="6" class="mb-2">
                <b>Data de Expedição</b> <br>
                {{ propostaState.titular.rgDataExpedicao | convertDateOutput }}
              </v-col>
              <v-col v-else cols="6" class="mb-2">
                <b>Data Expedição</b> <br>
                {{ '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>PIS/PASEP</b> <br>
                {{ propostaState.titular && propostaState.titular.pisPasep || '--' }}
              </v-col>
            </v-row>

            <v-row no-gutters v-else>
              <v-col cols="6" class="mb-2">
                <b>Identidade</b> <br>
                {{ propostaState.titular && propostaState.titular.rg || '--' }}
              </v-col>

              <v-col cols="6" class="mb-2">
                <b>Orgão Emissor</b> <br>
                {{ propostaState.titular && propostaState.titular.rgOrgaoEmissor || '--' }}
              </v-col>

              <v-col cols="6" class="mb-2">
                <b>Data Expedição</b> <br>
                {{ propostaState.titular && propostaState.titular.rgDataExpedicao | convertDateOutput }}
              </v-col>

              <v-col cols="6" class="mb-2">
                <b>PIS/PASEP</b> <br>
                {{ propostaState.titular && propostaState.titular.pisPasep || '--' }}
              </v-col>
              <v-col v-if="propostaState.titular && propostaState.titular.declaracaoNascidoVivo" cols="6" class="mb-2">
                <b>Declaração Nascido Vivo</b> <br>
                {{ propostaState.titular && propostaState.titular.declaracaoNascidoVivo || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Portabilidade</b> <br>
                {{ propostaState.titular && propostaState.titular.portabilidade !== undefined
                ?
                propostaState.titular.portabilidade ? "Sim" : "Não"
                :
                 propostaState.possuiPortabilidade ? "Sim" : "Não" }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Segmentação Anterior</b> <br>
                {{ propostaState.titular && propostaState.titular.segmentacaoAnterior || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Redução de carência</b> <br>
                  {{ propostaState.titular.possuiPlano ? "Sim" : "Não" || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2" v-if="propostaState.plano && propostaState.plano.operadora.toLowerCase() === 'unimed bh' " >
                <b>Carteirinha anterior</b> <br>
                  {{ propostaState.titular.campoCarteirinha || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Matrícula Funcional</b> <br>
                  {{ propostaState.titular.matriculaFuncional || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2" v-if="propostaState.titular.empossadoPensionista" >
                <b>Recém Empossado/Pensionista</b> <br>
                  {{ propostaState.titular.empossadoPensionista || '--' }}
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">
              Endereço Residencial
            </h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="mb-4"></v-divider>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>CEP</b> <br>
                {{ enderecoResidencial && enderecoResidencial.cep || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Endereço Cobrança?</b> <br>
                {{ enderecoCobranca === 'residencial' ? 'SIM' : 'NÃO' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Cidade</b> <br>
                {{ enderecoResidencial && enderecoResidencial.cidade || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>UF</b> <br>
                {{ enderecoResidencial && enderecoResidencial.estado || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Logradouro</b> <br>
                {{ enderecoResidencial && enderecoResidencial.logradouro || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Número</b> <br>
                {{ enderecoResidencial && enderecoResidencial.numero || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Bairro</b> <br>
                {{ enderecoResidencial && enderecoResidencial.bairro || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Complemento</b> <br>
                {{ enderecoResidencial && enderecoResidencial.complemento || '--' }}
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">
              Endereço Comercial
            </h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="mb-4"></v-divider>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>CEP</b> <br>
                {{ enderecoComercial && enderecoComercial.cep || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Endereço Cobrança?</b> <br>
                {{ enderecoCobranca === 'comercial' ? 'SIM' : 'NÃO' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Cidade</b> <br>
                {{ enderecoComercial && enderecoComercial.cidade || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>UF</b> <br>
                {{ enderecoComercial && enderecoComercial.estado || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Logradouro</b> <br>
                {{ enderecoComercial && enderecoComercial.logradouro || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Número</b> <br>
                {{ enderecoComercial && enderecoComercial.numero || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Bairro</b> <br>
                {{ enderecoComercial && enderecoComercial.bairro || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Complemento</b> <br>
                {{ enderecoComercial && enderecoComercial.complemento || '--' }}
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="propostaState.responsavelLegal && propostaState.responsavelLegal.cpf" class="mb-2">
          <v-expansion-panel-header>
            <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">
              Responsável Legal
            </h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="mb-4"></v-divider>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Nome</b> <br>
                {{ propostaState.responsavelLegal && propostaState.responsavelLegal.nome || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Email</b> <br>
                {{ propostaState.responsavelLegal && propostaState.responsavelLegal.email || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>CPF</b> <br>
                {{ propostaState.responsavelLegal && propostaState.responsavelLegal.cpf || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Data de Nascimento</b> <br>
                {{ propostaState.responsavelLegal && propostaState.responsavelLegal.dataNascimento | convertDateOutput }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Sexo</b> <br>
                {{ propostaState.responsavelLegal && propostaState.responsavelLegal.sexo || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Estado Civil</b> <br>
                {{ propostaState.responsavelLegal && propostaState.responsavelLegal.estadoCivil || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Telefone Principal</b> <br>
                {{ celularResponsavelLegal && celularResponsavelLegal.numero || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Identidade</b> <br>
                {{ propostaState.responsavelLegal && propostaState.responsavelLegal.rg || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Grau de parentesco</b> <br>
                {{ propostaState.responsavelLegal && propostaState.responsavelLegal.parentesco || '--' }}
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="dadosComplementaresOperadora">
          <v-expansion-panel-header>
            <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">
              Dados Complementares da Operadora
            </h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="mb-4"></v-divider>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Matricula Funcional</b> <br>
                {{ propostaState.plano.matricula || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Patrocinadora</b> <br>
                {{ propostaState.plano.patrocinadora || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Categoria</b> <br>
                {{ propostaState.plano.categoria || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Nome do Pai</b> <br>
                {{ propostaState.plano.nomePai || '--' }}
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>
    </v-container>

    <v-footer fixed v-if="showEditButton">
      <v-col class="text-center pa-1" cols="12" >
        <v-btn large color="primary" class="secundaryBtn--text" @click="edit">
          Editar dados
        </v-btn>
      </v-col>
    </v-footer>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import filters from '@/filters';
import { Etapas } from '@/utils/etapas';

export default {
  props: {
    showBotao: Boolean,
    etapa: Object,
    validarEdicao: Function,
  },
  data() {
    return {
      panel: [],
      items: 3,
    };
  },
  mounted() {
    this.expandirTodos();
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
      dadosTitularState: (state) => state.cadastroProposta.titular,
    }),
    enderecoResidencial() {
      if (!(this.propostaState.titular && this.propostaState.titular.enderecos)) return '';
      return this.propostaState.titular.enderecos.find(({ tipo }) => tipo.indexOf('ENDERECO_RESIDENCIAL') > -1);
    },
    enderecoComercial() {
      if (!(this.propostaState.titular && this.propostaState.titular.enderecos)) return '';
      return this.propostaState.titular.enderecos.find(({ tipo }) => tipo.indexOf('ENDERECO_COMERCIAL') > -1);
    },
    celularTitular() {
      if (!(this.propostaState.titular && this.propostaState.titular.telefones)) return '';
      return this.propostaState.titular.telefones.find((i) => i.tipo === 'CELULAR' && i.preferencial === true);
    },
    celularResponsavelLegal() {
      if (!(this.propostaState.responsavelLegal && this.propostaState.responsavelLegal.telefones)) return '';
      return this.propostaState.responsavelLegal.telefones.find((i) => i.tipo === 'CELULAR' && i.preferencial === true);
    },
    enderecoCobranca() {
      if (!(this.propostaState.titular && this.propostaState.titular.enderecos)) return '';
      const endCob = this.propostaState.titular.enderecos.find(({ tipo }) => tipo.indexOf('ENDERECO_COBRANCA') > -1);
      const endRes = this.enderecoResidencial;
      const endCom = this.enderecoComercial;
      if (endCob && endRes && endCob.cep === endRes.cep) return 'residencial';
      if (endCob && endCom && endCob.cep === endCom.cep) return 'comercial';
      return '';
    },
    showEditButton() {
      this.carregarEtapa();
      return (this.showBotao && this.etapa && this.etapaTitular && this.etapa.ordem >= this.etapaTitular.ordem);
    },
    telefonesOpcionais() {
      if (!(this.propostaState.titular && this.propostaState.titular.telefones)) return '';
      const telefones = this.propostaState.titular.telefones.filter((i) => i.preferencial === false);
      return telefones;
    },
    dadosComplementaresOperadora() {
      const { plano } = this.propostaState;
      const dadosComplementares = plano && (plano.nomePai || plano.patrocinadora || plano.categoria || plano.matricula);
      return dadosComplementares;
    },
  },
  methods: {
    ...mapActions({
      setBlnContinuar: 'cadastroProposta/setBlnContinuar',
    }),
    expandirTodos() {
      if (this.propostaState.responsavelLegal) this.items = 4;

      const { plano } = this.propostaState;
      if (plano && (plano.nomePai || plano.matricula || plano.patrocinadora || plano.nomePai)) this.items++;

      this.panel = [...Array(this.items).keys()].map((k, i) => i);
    },
    carregarEtapa() {
      const objEtapas = new Etapas();
      objEtapas.loadEtapas();
      this.etapaTitular = objEtapas.getEtapaAtual("dados_titular_contratacao");
    },
    async edit() {
      const permiteEditar = await this.validarEdicao();
      if (!permiteEditar) {
        this.$emit('showmsgeditar');
        this.$emit('closedialog');
        return;
      }

      this.carregarEtapa();
      const idade = this.getAge();
      const page = this.etapa.ordem >= this.etapaTitular.ordem ? this.etapaTitular.ordem : 0;
      if (idade < 18) {
        this.setBlnContinuar({ flag: true, page, isEditingRL: true });
      } else {
        this.setBlnContinuar({ flag: true, page, isEditing: true });
      }
      this.$router.push({ name: 'areaLogada.criarProposta' });
    },
    getAge() {
      if (this.dadosTitularState && this.dadosTitularState.dataNascimento) {
        return filters.getAge(new Date(this.dadosTitularState.dataNascimento));
      }
      return 18;
    },
  },
};
</script>

<style lang="scss" scoped>

  .header-height {
    padding: 20px;
    height: 70px;
  }

</style>
