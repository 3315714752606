var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.produtosOpcionais
    ? _c(
        "v-expansion-panels",
        { staticClass: "d-flex justify-start flex-wrap" },
        _vm._l(_vm.produtosOpcionais, function (produtoOpcional, index) {
          return _c(
            "v-col",
            {
              key: index,
              staticClass: "pa-0",
              attrs: { cols: "6", md: "6", sm: "12" },
            },
            [
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    { attrs: { tile: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "espacamento-linhas pa-0" },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-lg-center mt-0",
                                  attrs: { cols: "2", md: "3" },
                                },
                                [
                                  _c("v-icon", { attrs: { size: "45" } }, [
                                    _vm._v("mdi-file-document-multiple"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "8", md: "7" },
                                },
                                [
                                  _c("span", {
                                    staticClass: "mb-0 espacamento-linhas",
                                  }),
                                  _c("br"),
                                  _c("strong", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "mb-0 espacamento-linhas",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            produtoOpcional.nomePlanoAns ||
                                              "PRODUTO OPCIONAL"
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pb-0" },
                                    [
                                      _c(
                                        "v-list-item",
                                        { staticClass: "pa-0" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticClass:
                                                    "subtitle-2 font-weight-bold mb-0",
                                                },
                                                [_vm._v("Nome")]
                                              ),
                                              _c(
                                                "v-list-item-subtitle",
                                                {
                                                  staticClass:
                                                    "subtitle-1 text-uppercase text-wrap",
                                                  staticStyle: {
                                                    "line-height": "1.2",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      produtoOpcional.nomePlanoAns ||
                                                        "--"
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "pa-0" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticClass:
                                                    "subtitle-2 font-weight-bold mb-0",
                                                },
                                                [_vm._v("Valor Total")]
                                              ),
                                              _c(
                                                "v-list-item-subtitle",
                                                {
                                                  staticClass:
                                                    "subtitle-1 text-uppercase text-wrap",
                                                  staticStyle: {
                                                    "line-height": "1.2",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      produtoOpcional.valorTotal ||
                                                        "--"
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "mb-2 mt-2" }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c(
                                "v-list-item",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "subtitle-2 font-weight-bold mb-0",
                                        },
                                        [_vm._v("Operadora")]
                                      ),
                                      _c(
                                        "v-list-item-subtitle",
                                        {
                                          staticClass:
                                            "subtitle-1 text-uppercase",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              produtoOpcional.operadora || "--"
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "subtitle-2 font-weight-bold mb-0",
                                        },
                                        [_vm._v("Abrangência")]
                                      ),
                                      _c(
                                        "v-list-item-subtitle",
                                        {
                                          staticClass:
                                            "subtitle-1 text-uppercase",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              produtoOpcional.abrangencia ||
                                                "--"
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "subtitle-2 font-weight-bold mb-0",
                                        },
                                        [_vm._v("Segmentação")]
                                      ),
                                      _c(
                                        "v-list-item-subtitle",
                                        {
                                          staticClass:
                                            "subtitle-1 text-uppercase",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              produtoOpcional.segmentacao ||
                                                "--"
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "subtitle-2 font-weight-bold mb-0",
                                        },
                                        [_vm._v("Reembolso")]
                                      ),
                                      _c(
                                        "v-list-item-subtitle",
                                        {
                                          staticClass:
                                            "subtitle-1 text-uppercase",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.exibirReembolso(
                                                produtoOpcional
                                              ) || "--"
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "subtitle-2 font-weight-bold mb-0",
                                        },
                                        [_vm._v("Observação")]
                                      ),
                                      _c(
                                        "v-list-item-subtitle",
                                        {
                                          staticClass:
                                            "subtitle-1 text-uppercase",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              produtoOpcional.observacaoPlano ||
                                                "--"
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !produtoOpcional.opcionalExigeConfirmacaoCliente
                        ? _c(
                            "h1",
                            {
                              staticClass:
                                "title font-weight-bold mb-1 mt-4 primary--text",
                              staticStyle: { "line-height": "1" },
                            },
                            [_vm._v(" Beneficiários ")]
                          )
                        : _vm._e(),
                      _c("v-divider", { staticClass: "mb-2 mt-2" }),
                      !produtoOpcional.opcionalExigeConfirmacaoCliente
                        ? _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _vm._l(
                                _vm.beneficiarioTitular(produtoOpcional),
                                function (beneficiario, indexTt) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: indexTt,
                                      staticClass: "mt-2 mr-2",
                                      attrs: { cols: "12", md: "12", sm: "12" },
                                    },
                                    [
                                      _c("base-card-beneficiary", {
                                        attrs: {
                                          nome: _vm.propostaState.titular.nome,
                                          valor: beneficiario.valor,
                                          tipo: _vm.isTipo(
                                            _vm.propostaState.titular.nome
                                          ),
                                          avatar: _vm.isTipo(
                                            _vm.propostaState.titular.nome
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _vm._l(
                                _vm.beneficiariosDependentes(produtoOpcional),
                                function (beneficiario, indexDp) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: indexDp + 1,
                                      staticClass: "mt-2 mr-2",
                                      attrs: { cols: "12", md: "12", sm: "12" },
                                    },
                                    [
                                      _c("base-card-beneficiary", {
                                        attrs: {
                                          nome: beneficiario.nome,
                                          valor: beneficiario.valor,
                                          tipo: _vm.isTipo(beneficiario.nome),
                                          avatar: _vm.isTipo(beneficiario.nome),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }