<template>
  <v-dialog
    :retain-focus="false"
    no-click-animation
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card tile>
      <v-app-bar color="primary" dark fixed>
        <v-container class="d-flex align-center pa-0">
          <v-btn class="mr-3" small icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="mt-1">
            <div>
              <h1 class="title font-weight-bold" style="line-height: 1.3">
                Origem da Venda
              </h1>
            </div>
          </v-toolbar-title>
        </v-container>
      </v-app-bar>

      <div class="header-height"></div>

      <v-container class="pa-md-5 pt-md-5" style="max-width: 800px">
        <v-row justify="center" align="center">
          <v-col xl="10" cols="12" class="">
            <div
              v-text="'Selecione onde foi originada sua venda:'"
              class="text-h6 mb-3 ml-5"
            ></div>
          </v-col>
          <v-col xl="10" cols="12" class="">
            <v-radio-group
              class="mt-0"
              v-model="data"
            >
              <v-list-item v-for="(origemVenda, index) of datas" :key="index">
                <v-list-item-content>
                  <v-radio
                    class="ml-2"
                    :label="origemVenda.descricao"
                    :value="origemVenda"
                  ></v-radio>
                  <v-expand-transition>
                    <v-sheet
                      class="mt-2"
                      v-if="origemVenda.permiteObservacao && origemSelecionada(origemVenda)"
                      height="100"
                      tile
                    >
                      <v-textarea
                        height="100"
                        outlined
                        name="obs"
                        label="Informe mais detalhes"
                        v-model="data.observacao"
                        auto-grow
                      ></v-textarea>
                    </v-sheet>
                  </v-expand-transition>
                </v-list-item-content>
              </v-list-item>
            </v-radio-group>

            <div class="d-flex justify-md-end justify-center pr-md-15">
              <v-btn
                :data-test-id="'salvar-continuar'"
                :id="'salvar-continuar'"
                type="button"
                elevation="10"
                color="primary"
                class="secundaryBtn--text mt-4 pl-10 pr-10"
                :disabled="disableBtn"
                :loading="loadingBtn"
                @click="salvarOrigemVenda"
              >
                Salvar e continuar
              </v-btn>
            </div>

          </v-col>
        </v-row>
      </v-container>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import propostaService from "@/services/propostaService";

export default {
  props: {
    origensVenda: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      datas: [],
      loadingBtn: false,
      data: null,
    };
  },
  watch: {
    origensVenda(newValue) {
      this.datas = JSON.parse(JSON.stringify(newValue || []));
    },
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
    disableBtn() {
      if (!this.data) {
        return true;
      }
      if (this.data.permiteObservacao && !(this.data.observacao || '').trim()) {
        return true;
      }
      return false;
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.resetDados();
      this.$emit('recarregardados');
    },
    resetDados() {
      this.data = null;
    },
    transmitir() {
      this.dialog = false;
      this.resetDados();
      this.$emit('transmitir');
    },
    origemSelecionada(origemVenda) {
      return (this.data && this.data.id) === origemVenda.id;
    },
    async salvarOrigemVenda() {
      if (this.disableBtn) {
        this.$root.$snackBar.open({
          color: "error",
          message: 'Preencha a origem de venda',
        });
        return;
      }
      if (!this.data.permiteObservacao) {
        this.data.observacao = '';
      }
      try {
        this.loadingBtn = true;
        await propostaService.origemVenda({
          guidProposta: this.propostaState.guid,
          descricao: this.data.descricao,
          observacao: this.data.observacao.trim(),
        });
        this.transmitir();
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || 'Erro ao vincular lead',
        });
      } finally {
        this.loadingBtn = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
