<template>
  <div>
    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title>
          <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">
            Atenção!
          </h2>
        </v-card-title>
          <v-card-text>
            Deseja vincular a proposta à um lead existente?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-1"
              color="red darken-1"
              text
              @click="$emit('nao')"
            >
              Não
            </v-btn>
            <v-btn
              color="primary"
              text
              type="submit"
              @click="$emit('sim')"
            >
              Sim
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
