var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closedialog")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Dados titular ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Informações pessoais e endereço ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pb-5 pt-0 elevation-0" },
        [
          _c(
            "v-expansion-panels",
            {
              staticClass: "pb-5 elevation-0",
              attrs: { multiple: "" },
              model: {
                value: _vm.panel,
                callback: function ($$v) {
                  _vm.panel = $$v
                },
                expression: "panel",
              },
            },
            [
              _c(
                "v-expansion-panel",
                { staticClass: "elevation-0" },
                [
                  _c("v-expansion-panel-header", [
                    _c(
                      "h1",
                      {
                        staticClass:
                          "title font-weight-bold mb-1 primary--text",
                        staticStyle: { "line-height": "1" },
                      },
                      [_vm._v(" Dados básicos ")]
                    ),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("v-divider", { staticClass: "mb-4" }),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Nome")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.titular &&
                                      _vm.propostaState.titular.nome) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Email")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.titular &&
                                      _vm.propostaState.titular.email) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("CPF")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.titular &&
                                      _vm.propostaState.titular.cpf) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Data de Nascimento")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("convertDateOutput")(
                                      _vm.propostaState.titular &&
                                        _vm.propostaState.titular.dataNascimento
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Nome da Mãe")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.titular &&
                                      _vm.propostaState.titular.nomeMae) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Sexo")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.titular &&
                                      _vm.propostaState.titular.sexo) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Estado Civil")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.titular &&
                                      _vm.propostaState.titular.estadoCivil) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Telefone Principal")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.celularTitular &&
                                      _vm.celularTitular.numero) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Telefone Opcional")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._l(
                                _vm.telefonesOpcionais,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "align-row pl-3 pt-3 mb-1",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                (_vm.telefonesOpcionais &&
                                                  _vm.telefonesOpcionais[index]
                                                    .numero) ||
                                                  "--"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm.propostaState.titular && _vm.propostaState.titular.cns
                        ? _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "12" } },
                                [
                                  _c("b", [_vm._v("Cartão Nacional de Saúde")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (_vm.propostaState.titular &&
                                          _vm.propostaState.titular.cns) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.propostaState.plano &&
                      _vm.propostaState.plano.operadora.toLowerCase() ===
                        "unimed volta redonda"
                        ? _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Identidade")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (_vm.propostaState.titular &&
                                          _vm.propostaState.titular.rg) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Orgão Emissor")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (_vm.propostaState.titular &&
                                          _vm.propostaState.titular
                                            .rgOrgaoEmissor) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("UF Emissor")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (_vm.propostaState.titular &&
                                          _vm.propostaState.titular
                                            .rgOrgaoEmissorUf) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm.propostaState.titular &&
                              _vm.propostaState.titular.rgDataExpedicao
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c("b", [_vm._v("Data de Expedição")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("convertDateOutput")(
                                              _vm.propostaState.titular
                                                .rgDataExpedicao
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "v-col",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c("b", [_vm._v("Data Expedição")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" " + _vm._s("--") + " "),
                                    ]
                                  ),
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("PIS/PASEP")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (_vm.propostaState.titular &&
                                          _vm.propostaState.titular.pisPasep) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Identidade")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (_vm.propostaState.titular &&
                                          _vm.propostaState.titular.rg) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Orgão Emissor")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (_vm.propostaState.titular &&
                                          _vm.propostaState.titular
                                            .rgOrgaoEmissor) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Data Expedição")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("convertDateOutput")(
                                          _vm.propostaState.titular &&
                                            _vm.propostaState.titular
                                              .rgDataExpedicao
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("PIS/PASEP")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (_vm.propostaState.titular &&
                                          _vm.propostaState.titular.pisPasep) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm.propostaState.titular &&
                              _vm.propostaState.titular.declaracaoNascidoVivo
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v("Declaração Nascido Vivo"),
                                      ]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            (_vm.propostaState.titular &&
                                              _vm.propostaState.titular
                                                .declaracaoNascidoVivo) ||
                                              "--"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Portabilidade")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.propostaState.titular &&
                                      _vm.propostaState.titular
                                        .portabilidade !== undefined
                                      ? _vm.propostaState.titular.portabilidade
                                        ? "Sim"
                                        : "Não"
                                      : _vm.propostaState.possuiPortabilidade
                                      ? "Sim"
                                      : "Não"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Segmentação Anterior")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.propostaState.titular &&
                                      _vm.propostaState.titular
                                        .segmentacaoAnterior) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Redução de carência")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.propostaState.titular.possuiPlano
                                      ? "Sim"
                                      : "Não" || "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _vm.propostaState.plano &&
                          _vm.propostaState.plano.operadora.toLowerCase() ===
                            "unimed bh"
                            ? _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Carteirinha anterior")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.propostaState.titular
                                          .campoCarteirinha || "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Matrícula Funcional")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.propostaState.titular
                                      .matriculaFuncional || "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _vm.propostaState.titular.empossadoPensionista
                            ? _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [
                                    _vm._v("Recém Empossado/Pensionista"),
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.propostaState.titular
                                          .empossadoPensionista || "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _c(
                      "h1",
                      {
                        staticClass:
                          "title font-weight-bold mb-1 primary--text",
                        staticStyle: { "line-height": "1" },
                      },
                      [_vm._v(" Endereço Residencial ")]
                    ),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("v-divider", { staticClass: "mb-4" }),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("CEP")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.enderecoResidencial &&
                                      _vm.enderecoResidencial.cep) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Endereço Cobrança?")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.enderecoCobranca === "residencial"
                                      ? "SIM"
                                      : "NÃO"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Cidade")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.enderecoResidencial &&
                                      _vm.enderecoResidencial.cidade) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("UF")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.enderecoResidencial &&
                                      _vm.enderecoResidencial.estado) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Logradouro")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.enderecoResidencial &&
                                      _vm.enderecoResidencial.logradouro) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Número")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.enderecoResidencial &&
                                      _vm.enderecoResidencial.numero) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Bairro")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.enderecoResidencial &&
                                      _vm.enderecoResidencial.bairro) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Complemento")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.enderecoResidencial &&
                                      _vm.enderecoResidencial.complemento) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _c(
                      "h1",
                      {
                        staticClass:
                          "title font-weight-bold mb-1 primary--text",
                        staticStyle: { "line-height": "1" },
                      },
                      [_vm._v(" Endereço Comercial ")]
                    ),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("v-divider", { staticClass: "mb-4" }),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("CEP")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.enderecoComercial &&
                                      _vm.enderecoComercial.cep) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Endereço Cobrança?")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.enderecoCobranca === "comercial"
                                      ? "SIM"
                                      : "NÃO"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("Cidade")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.enderecoComercial &&
                                      _vm.enderecoComercial.cidade) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "6" } },
                            [
                              _c("b", [_vm._v("UF")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.enderecoComercial &&
                                      _vm.enderecoComercial.estado) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Logradouro")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.enderecoComercial &&
                                      _vm.enderecoComercial.logradouro) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Número")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.enderecoComercial &&
                                      _vm.enderecoComercial.numero) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Bairro")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.enderecoComercial &&
                                      _vm.enderecoComercial.bairro) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c("b", [_vm._v("Complemento")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.enderecoComercial &&
                                      _vm.enderecoComercial.complemento) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.propostaState.responsavelLegal &&
              _vm.propostaState.responsavelLegal.cpf
                ? _c(
                    "v-expansion-panel",
                    { staticClass: "mb-2" },
                    [
                      _c("v-expansion-panel-header", [
                        _c(
                          "h1",
                          {
                            staticClass:
                              "title font-weight-bold mb-1 primary--text",
                            staticStyle: { "line-height": "1" },
                          },
                          [_vm._v(" Responsável Legal ")]
                        ),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("v-divider", { staticClass: "mb-4" }),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "12" } },
                                [
                                  _c("b", [_vm._v("Nome")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (_vm.propostaState.responsavelLegal &&
                                          _vm.propostaState.responsavelLegal
                                            .nome) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "12" } },
                                [
                                  _c("b", [_vm._v("Email")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (_vm.propostaState.responsavelLegal &&
                                          _vm.propostaState.responsavelLegal
                                            .email) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("CPF")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (_vm.propostaState.responsavelLegal &&
                                          _vm.propostaState.responsavelLegal
                                            .cpf) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Data de Nascimento")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("convertDateOutput")(
                                          _vm.propostaState.responsavelLegal &&
                                            _vm.propostaState.responsavelLegal
                                              .dataNascimento
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Sexo")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (_vm.propostaState.responsavelLegal &&
                                          _vm.propostaState.responsavelLegal
                                            .sexo) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Estado Civil")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (_vm.propostaState.responsavelLegal &&
                                          _vm.propostaState.responsavelLegal
                                            .estadoCivil) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "12" } },
                                [
                                  _c("b", [_vm._v("Telefone Principal")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (_vm.celularResponsavelLegal &&
                                          _vm.celularResponsavelLegal.numero) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Identidade")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (_vm.propostaState.responsavelLegal &&
                                          _vm.propostaState.responsavelLegal
                                            .rg) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Grau de parentesco")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (_vm.propostaState.responsavelLegal &&
                                          _vm.propostaState.responsavelLegal
                                            .parentesco) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dadosComplementaresOperadora
                ? _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _c(
                          "h1",
                          {
                            staticClass:
                              "title font-weight-bold mb-1 primary--text",
                            staticStyle: { "line-height": "1" },
                          },
                          [_vm._v(" Dados Complementares da Operadora ")]
                        ),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("v-divider", { staticClass: "mb-4" }),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Matricula Funcional")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.propostaState.plano.matricula ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Patrocinadora")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.propostaState.plano.patrocinadora ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Categoria")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.propostaState.plano.categoria ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mb-2", attrs: { cols: "6" } },
                                [
                                  _c("b", [_vm._v("Nome do Pai")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.propostaState.plano.nomePai || "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showEditButton
        ? _c(
            "v-footer",
            { attrs: { fixed: "" } },
            [
              _c(
                "v-col",
                { staticClass: "text-center pa-1", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secundaryBtn--text",
                      attrs: { large: "", color: "primary" },
                      on: { click: _vm.edit },
                    },
                    [_vm._v(" Editar dados ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }