var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "retain-focus": false,
        "no-click-animation": "",
        persistent: "",
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c(
            "v-app-bar",
            { attrs: { color: "primary", dark: "", fixed: "" } },
            [
              _c(
                "v-container",
                { staticClass: "d-flex align-center pa-0" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: { small: "", icon: "", dark: "" },
                      on: { click: _vm.closeDialog },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("v-toolbar-title", { staticClass: "mt-1" }, [
                    _c("div", [
                      _c(
                        "h1",
                        {
                          staticClass: "title font-weight-bold",
                          staticStyle: { "line-height": "1.3" },
                        },
                        [_vm._v(" Origem da Venda ")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "header-height" }),
          _c(
            "v-container",
            {
              staticClass: "pa-md-5 pt-md-5",
              staticStyle: { "max-width": "800px" },
            },
            [
              _c(
                "v-row",
                { attrs: { justify: "center", align: "center" } },
                [
                  _c("v-col", { attrs: { xl: "10", cols: "12" } }, [
                    _c("div", {
                      staticClass: "text-h6 mb-3 ml-5",
                      domProps: {
                        textContent: _vm._s(
                          "Selecione onde foi originada sua venda:"
                        ),
                      },
                    }),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { xl: "10", cols: "12" } },
                    [
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "mt-0",
                          model: {
                            value: _vm.data,
                            callback: function ($$v) {
                              _vm.data = $$v
                            },
                            expression: "data",
                          },
                        },
                        _vm._l(_vm.datas, function (origemVenda, index) {
                          return _c(
                            "v-list-item",
                            { key: index },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-radio", {
                                    staticClass: "ml-2",
                                    attrs: {
                                      label: origemVenda.descricao,
                                      value: origemVenda,
                                    },
                                  }),
                                  _c(
                                    "v-expand-transition",
                                    [
                                      origemVenda.permiteObservacao &&
                                      _vm.origemSelecionada(origemVenda)
                                        ? _c(
                                            "v-sheet",
                                            {
                                              staticClass: "mt-2",
                                              attrs: {
                                                height: "100",
                                                tile: "",
                                              },
                                            },
                                            [
                                              _c("v-textarea", {
                                                attrs: {
                                                  height: "100",
                                                  outlined: "",
                                                  name: "obs",
                                                  label:
                                                    "Informe mais detalhes",
                                                  "auto-grow": "",
                                                },
                                                model: {
                                                  value: _vm.data.observacao,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.data,
                                                      "observacao",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "data.observacao",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-md-end justify-center pr-md-15",
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "secundaryBtn--text mt-4 pl-10 pr-10",
                              attrs: {
                                "data-test-id": "salvar-continuar",
                                id: "salvar-continuar",
                                type: "button",
                                elevation: "10",
                                color: "primary",
                                disabled: _vm.disableBtn,
                                loading: _vm.loadingBtn,
                              },
                              on: { click: _vm.salvarOrigemVenda },
                            },
                            [_vm._v(" Salvar e continuar ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }