var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closedialog")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Validação de Dados ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Transmitir Proposta ")]
                  ),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.validarProposta } },
                [_c("v-icon", [_vm._v("mdi-reload")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _vm.loadingDatas
        ? _c(
            "div",
            { staticClass: "d-flex justify-center mt-10 pa-10" },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.propostaDuplicada
                ? _c("modal-confirmation", {
                    attrs: {
                      show: _vm.cancelarPropostaDuplicada.showConfirm,
                      name: "cancelar",
                      loading: _vm.cancelarPropostaDuplicada.loading,
                      title: _vm.cancelarPropostaDuplicada.title,
                      text: _vm.cancelarPropostaDuplicada.text,
                      buttonConfirm: _vm.cancelarPropostaDuplicada.confirm,
                      buttonCancel: _vm.cancelarPropostaDuplicada.cancel,
                      width: 500,
                      persistent: true,
                    },
                    on: {
                      close: _vm.cancelarPropostaDuplicada.callAfterCancel,
                      confirm: _vm.cancelarPropostaDuplicada.callAfterConfirm,
                    },
                  })
                : !_vm.prontaTransmissao
                ? _c("pendente", {
                    attrs: {
                      pendenciasPreenchimento: _vm.pendenciasPreenchimento,
                      erros: _vm.erros,
                    },
                  })
                : _c("concluido", {
                    on: {
                      concluir: function ($event) {
                        return _vm.$emit("closedialog")
                      },
                    },
                  }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }