<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('closedialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1" style="line-height: 1">
              Dados Profissionais
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Informações profissionais do titular
            </h3>
          </div>
        </v-toolbar-title>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>

    <v-container class="pa-5 pt-0">
      <v-row no-gutters>
        <v-col cols="12" class="mb-2 mt-2">
          <b>Profissão</b> <br>
           {{ propostaState.titular && propostaState.titular.publicoAlvo || '--' }}
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-2">
        <v-col cols="12">
          <b>Entidade</b> <br>
          {{ propostaState.titular && propostaState.titular.entidade || '--' }}
        </v-col>
      </v-row>
      <v-divider class="mb-4 mt-2"></v-divider>
      <v-row no-gutters class="mb-2">
        <v-col cols="12">
          <b>CNPJ</b> <br>
          {{ propostaState.titular && propostaState.titular.dadosProfissionais && propostaState.titular.dadosProfissionais.cnpj || '--' }}
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-2">
        <v-col cols="12">
          <b>Nome Fantasia</b> <br>
          {{ propostaState.titular && propostaState.titular.dadosProfissionais && propostaState.titular.dadosProfissionais.nomeFantasia || '--' }}
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-2">
        <v-col cols="12">
          <b>Razão Social</b> <br>
          {{ propostaState.titular && propostaState.titular.dadosProfissionais && propostaState.titular.dadosProfissionais.razaoSocial || '--' }}
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-2">
        <v-col cols="12">
          <b>Ramo de Atividade</b> <br>
          {{ propostaState.titular && propostaState.titular.dadosProfissionais && propostaState.titular.dadosProfissionais.ramoAtividade || '--' }}
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-2">
        <v-col cols="12">
          <b>Sindicato</b> <br>
          {{ propostaState.titular && propostaState.titular.dadosProfissionais && propostaState.titular.dadosProfissionais.sindicato || '--' }}
        </v-col>
      </v-row>
    </v-container>

    <v-footer fixed v-if="showEditButton">
      <v-col class="text-center pa-1" cols="12">
        <v-btn large color="primary" class="secundaryBtn--text" @click="edit">
          Editar dados
        </v-btn>
      </v-col>
    </v-footer>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Etapas } from '@/utils/etapas';

export default {
  props: {
    showBotao: Boolean,
    etapa: Object,
    validarEdicao: Function,
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
    showEditButton() {
      this.carregarEtapa();
      return (this.etapa && this.etapa.ordem >= this.etapaDadosProfissionais.ordem && this.showBotao);
    },
  },
  data: () => ({
    etapaDadosProfissionais: {},
  }),
  methods: {
    ...mapActions({
      setBlnContinuar: 'cadastroProposta/setBlnContinuar',
    }),
    carregarEtapa() {
      const objEtapas = new Etapas();
      objEtapas.loadEtapas();
      this.etapaDadosProfissionais = objEtapas.getEtapaAtual("dados_profissionais");
    },
    async edit() {
      const permiteEditar = await this.validarEdicao();
      if (!permiteEditar) {
        this.$emit('showmsgeditar');
        this.$emit('closedialog');
        return;
      }

      this.carregarEtapa();
      this.setBlnContinuar({ flag: true, page: this.etapaDadosProfissionais.ordem, isEditing: true });
      this.$router.push({ name: 'areaLogada.criarProposta' });
    },
  },
};
</script>

<style lang="scss" scoped>

  .header-height {
    padding: 20px;
    height: 70px;
  }

</style>
