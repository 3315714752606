<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('closedialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1" style="line-height: 1">
              Dados Portabilidade
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Portabilidade dos beneficiários
            </h3>
          </div>
        </v-toolbar-title>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>

    <v-container class="pa-5 pt-0" v-if="getBeneficiarios.length > 0">
      <v-expansion-panels v-model="panel" multiple class="mb-12">
        <v-expansion-panel v-for="(item, i) in getBeneficiarios" :key="i">
          <v-expansion-panel-header>
            <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">
              {{item.nome}}
            </h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="mb-4"></v-divider>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Portabilidade</b> <br>
                {{ item && item.portabilidade != undefined ? item.portabilidade ? "Sim" : "Não" : propostaState.possuiPortabilidade ? "Sim" : "Não" }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Segmentação Anterior</b> <br>
                {{ item && item.segmentacaoAnterior || '--' }}
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
    <v-container class="pt-2 text-center h-100" v-else>
      Nenhum beneficiário cadastrado
    </v-container>

    <v-footer fixed v-if="showEditButton">
      <v-col class="text-center pa-1" cols="12" >
        <v-btn large color="primary" class="secundaryBtn--text" @click="edit">
          Editar dados
        </v-btn>
      </v-col>
    </v-footer>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Etapas } from '@/utils/etapas';

export default {
  props: {
    showBotao: Boolean,
    etapa: Object,
    validarEdicao: Function,
  },
  data() {
    return {
      panel: [],
      etapaPortabilidade: {},
    };
  },
  mounted() {
    this.expandirTodos();
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
    getBeneficiarios() {
      const dependentesExistentes = this.propostaState.dependentes.filter((dep) => dep.nome && dep.guid);
      return [this.propostaState.titular, ...dependentesExistentes];
    },
    showEditButton() {
      this.carregarEtapa();
      return (this.etapa && this.etapa.ordem >= this.etapaPortabilidade.ordem && this.showBotao);
    },
  },
  methods: {
    ...mapActions({
      setBlnContinuar: 'cadastroProposta/setBlnContinuar',
    }),
    carregarEtapa() {
      const objEtapas = new Etapas();
      objEtapas.loadEtapas();
      this.etapaPortabilidade = objEtapas.getEtapaAtual("dados_portabilidade");
    },
    expandirTodos() {
      this.panel = [...Array(this.propostaState.dependentes.length).keys()].map((k, i) => i);
    },
    async edit() {
      const permiteEditar = await this.validarEdicao();
      if (!permiteEditar) {
        this.$emit('showmsgeditar');
        this.$emit('closedialog');
        return;
      }

      this.carregarEtapa();
      this.setBlnContinuar({ flag: true, page: this.etapaPortabilidade.ordem, isEditing: true });
      this.$router.push({ name: 'areaLogada.criarProposta' });
    },
  },
};
</script>

<style lang="scss" scoped>

  .header-height {
    padding: 20px;
    height: 70px;
  }

</style>
