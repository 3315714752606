<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('closedialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1" style="line-height: 1">
              Dados Plano
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Mais informações do plano selecionado
            </h3>
          </div>
        </v-toolbar-title>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>
    <v-container class="pa-5 pt-2 mb-15">
      <v-row no-gutters>
        <v-col cols="12" class="mb-2">
          <b>Nome</b> <br>
          {{ propostaState && propostaState.plano && propostaState.plano.nome || '--' }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <b>Valor Total</b><br>
           <span
              v-if="
                propostaState &&
                propostaState.plano &&
                propostaState.plano.valorTotalOdontoAdicional &&
                propostaState.plano.valorTotalOdontoAdicional > 0.00
                  "
                    >
                      {{
                        (parseFloat(propostaState.plano.valorTotal) +
                          parseFloat(propostaState.plano.valorTotalOdontoAdicional))
                          | currency
                      }}
             </span>
            <span
            v-else-if="propostaState.valorTotal < ((propostaState.plano && propostaState.plano.valorTotalSemDesconto) || -1)"
            class="text-decoration-line-through text-caption grey--text">
            {{ propostaState.plano.valorTotalSemDesconto | currency }}
          </span>
          <span v-else>{{ propostaState.valorTotalProposta | currency }}</span>
        </v-col>
      </v-row>
      <v-divider class="mb-4 mt-2"></v-divider>
      <v-row no-gutters>
        <v-col cols="12" class="mb-2">
          <b>Operadora</b> <br>
          {{ (propostaState.plano && propostaState.plano.nomeExibicaoOperadora) || plano.operadora || '--' }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="4" class="mb-2">
          <b>Abrangência</b> <br>
          {{ propostaState.plano && propostaState.plano.abrangencia || '--' }}
        </v-col>
        <v-col cols="4" class="mb-2">
          <b>Acomodação</b> <br>
          {{ propostaState.plano && propostaState.plano.acomodacao || '--' }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="4" class="mb-2">
          <b>Segmentação</b> <br>
          {{ propostaState.plano && propostaState.plano.segmentacao || '--' }}
        </v-col>
        <v-col cols="4" class="mb-2">
          <b>Súmula</b> <br>
          {{ propostaState.possuiSumula ? "Sim" : "Não" || '--' }}
        </v-col>
        <v-col cols="12">
          <v-chip class="px-3 mr-2 my-2" v-if="propostaState.plano && propostaState.plano.coparticipacao">Coparticipação</v-chip>
          <v-chip class="px-3 mr-2 my-2" color="#00cfb6" v-if="propostaState.plano && propostaState.plano.modeloCoparticipacao">Copart {{ propostaState.plano.modeloCoparticipacao }}</v-chip>
          <v-chip class="px-3 my-2" v-if="propostaState.plano && propostaState.plano.odonto">Com Odonto</v-chip>
        </v-col>
      </v-row>
      <v-row v-if="propostaState.hasOwnProperty('flagProdutoOpcional')" no-gutters>
        <v-col cols="4" class="mb-2">
          <b>Produtos opcionais</b> <br>
          {{ propostaState.produtosOpcionais && propostaState.produtosOpcionais.length > 0 ? "Sim" : "Não" }}
        </v-col>
      </v-row>
      <!-- <v-row v-if="propostaState.plano && propostaState.plano.operadora.toLowerCase() === 'unimed bh' " no-gutters>
        <v-col cols="4" class="mb-2">
          <b>Redução de carência</b> <br>
          {{ propostaState.titular.possuiPlano ? "Sim" : "Não" || '--' }}
        </v-col>
        <v-col cols="4" class="mb-2">
          <b>Carteirinha anterior</b> <br>
          {{ propostaState.titular.campoCarteirinha || '--' }}
        </v-col>
      </v-row> -->
      <h1 class="title font-weight-bold mb-1 mt-4 primary--text" style="line-height: 1">
        Beneficiários
      </h1>
      <v-divider class="mb-2 mt-2"></v-divider>
      <v-row no-gutters>
          <v-col cols="12" md="4" sm="6" class="mt-2 mr-2" v-for="(item, i) in beneficiarios" :key="i">
            <base-card-beneficiary
            :avatar="getTipo(item.tipo)"
            :nome="item.nome || item.dataNascimento"
            :valor="item.valor"
            :valorSemDesconto="item.valorSemDesconto"
            :tipo="item.tipo"
            :comOdonto="item.comOdonto"
            :valorOdonto="item.valorOdonto || 0"
            ></base-card-beneficiary>
          </v-col>
        </v-row>
      <br>
      <div v-if="produtosOpcionais.length > 0">
        <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">
          Produtos opcionais
        </h1>
        <v-divider class="mb-4 mt-2"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" class="mt-0 mr-2">
            <card-produto-opcional v-model="dialogProdutoOpcional" :produtosOpcionais="produtosOpcionais"> </card-produto-opcional>
          </v-col>
        </v-row>
      </div>
      <div v-if="propostaState.recusaProdutosOpcionais.length > 0">
        <h1 class="title font-weight-bold mb-1 mt-3 primary--text" style="line-height: 1">
          Recusas cliente
        </h1>
        <v-divider class="mb-4 mt-2"></v-divider>
        <p v-for="opcionalRecusado in propostaState.recusaProdutosOpcionais" :key="opcionalRecusado.idProdutoFatura">
          - Opcional <b>{{ opcionalRecusado.nome }}</b> recusado pelo cliente
        </p>        
      </div>
    </v-container>
    <v-footer v-if="showEditButton">
      <v-col class="text-center pa-1" cols="12" @click="edit">
        <v-btn large color="primary" class="secundaryBtn--text" :loading="loadingEdit">
          Editar dados
        </v-btn>
      </v-col>
    </v-footer>
    <v-footer v-else color="white"></v-footer>
  </div>
</template>

<script>
import planoService from '@/services/planoService';
import propostaService from '@/services/propostaService';
import BaseCardBeneficiary from '@/components/BaseCardBeneficiary.vue';
import { mapActions, mapState } from 'vuex';
import CardProdutoOpcional from '@/views/AreaLogada/DadosProposta/DadosPlano/components/card-produto-opcional.vue';
import { Etapas } from '@/utils/etapas';

export default {
  props: {
    showBotao: Boolean,
    etapa: Object,
    validarEdicao: Function,
  },
  components: { BaseCardBeneficiary, CardProdutoOpcional },
  data() {
    return {
      plano: {},
      dialogProdutoOpcional: false,
      produtosOpcionais: [],
      etapaPlanos: {},
      loadingEdit: false,
    };
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
    beneficiarios() {
      const dependentes = this.propostaState.dependentes.map(({
        nome, valor, valorSemDesconto, valorOdontoAdicional,
      }) => ({
        nome,
        valor,
        valorSemDesconto,
        valorOdonto: (parseFloat(valor) + parseFloat(valorOdontoAdicional)),
        comOdonto: this.propostaState.plano ? this.propostaState.plano.comOdonto : '',
        tipo: 'DEPENDENTE',
      }));
      const titular = {
        nome: this.propostaState.titular.nome,
        valor: this.propostaState.titular.valor,
        valorSemDesconto: this.propostaState.titular.valorSemDesconto,
        valorOdonto: (parseFloat(this.propostaState.titular.valor) + parseFloat(this.propostaState.titular.valorOdontoAdicional)),
        comOdonto: this.propostaState.plano ? this.propostaState.plano.comOdonto : '',
        tipo: 'TITULAR',
      };
      const arr = [];
      arr.push(titular);
      return [...arr, ...dependentes];
    },
    showEditButton() {
      return (this.etapa && this.etapa.ordem >= 0 && this.showBotao);
    },
  },
  async mounted() {
    await this.carregarDadosProdutosOpcionais();
  },
  methods: {
    ...mapActions({
      setBlnContinuar: 'cadastroProposta/setBlnContinuar',
    }),
    carregarEtapa() {
      const objEtapas = new Etapas();
      objEtapas.loadEtapas();
      this.etapaPlanos = objEtapas.getEtapaAtual("plano");
    },
    getTipo(value) {
      return this.$options.filters.avatarName(value);
    },
    async edit() {
      const permiteEditar = await this.validarEdicao();
      if (!permiteEditar) {
        this.$emit('showmsgeditar');
        this.$emit('closedialog');
        return;
      }

      this.carregarEtapa();
      if ((this.propostaState.status || '') === 'DEVOLVIDA') {
        this.setBlnContinuar({
          flag: true, page: 3, isEditingPlan: true, isEditingVigencia: true,
        });
        this.$router.push({ name: 'areaLogada.criarProposta' });
        return;
      }
      const resp = await this.$root.$confirmdialog.open(
        'Deseja continuar?',
        `Você será redirecionado ao passo inicial da proposta. <br><br>
         Ao realizar alterações que influenciam na filtragem plano, o mesmo será desconsiderado.
         Informações posteriores como: Entidade, Operadora, Vigência, e Declaração Pessoal de Saúde,
         também serão desconsideradas. <br><br>
         Será necessário selecionar e complementar algumas informações novamente.`,
      );
      if (resp) {
        try {
          const { guid: guidProposta } = this.propostaState;
          this.loadingEdit = true;
          await propostaService.atualizarEtapa({ guidProposta, etapa: 'corretora' });
          this.setBlnContinuar({ flag: true, page: 0, isEditingPlan: true });
          this.loadingEdit = false;
          if (localStorage.getItem('etapaBeforeReset')) {
            localStorage.removeItem('etapaBeforeReset');
          }
          this.$router.push({ name: 'areaLogada.criarProposta' });
        } catch (error) {
          this.loadingEdit = false;
          this.$root.$snackBar.open({
            color: "error",
            message: error.message || "Erro ao salvar dados, tente novamente mais tarde",
          });
        }
      }
    },
    carregarDadosProdutosOpcionais() {
      if (this.propostaState.produtosOpcionais && this.propostaState.produtosOpcionais.length > 0) {
        this.produtosOpcionais = this.propostaState.produtosOpcionais;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  .header-height {
    padding: 20px;
    height: 70px;
  }

</style>
