var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "retain-focus": false,
        "no-click-animation": "",
        persistent: "",
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c(
            "v-app-bar",
            { attrs: { color: "primary", dark: "", fixed: "" } },
            [
              _c(
                "v-container",
                { staticClass: "d-flex align-center pa-0" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: { small: "", icon: "", dark: "" },
                      on: { click: _vm.closeDialog },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("v-toolbar-title", { staticClass: "mt-1" }, [
                    _c("div", [
                      _c(
                        "h1",
                        {
                          staticClass: "title font-weight-bold",
                          staticStyle: { "line-height": "1.3" },
                        },
                        [_vm._v(" Vincular proposta ")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "header-height" }),
          _c(
            "v-container",
            { staticClass: "pa-5 pt-5" },
            [
              _c("div", {
                staticClass: "text-h6 mb-3",
                domProps: {
                  textContent: _vm._s("Selecione um lead existente:"),
                },
              }),
              _c("base-text-field", {
                attrs: {
                  id: "nome",
                  name: "nome",
                  label: "Busque por nome, email ou celular",
                  clearable: "",
                  outlined: "",
                  "prepend-inner-icon": "mdi-magnify",
                },
                on: { input: _vm.debounceInput },
                model: {
                  value: _vm.filtros.textSearch,
                  callback: function ($$v) {
                    _vm.$set(_vm.filtros, "textSearch", $$v)
                  },
                  expression: "filtros.textSearch",
                },
              }),
              _c(
                "v-row",
                { attrs: { justify: "end" } },
                [
                  _c("v-col", { attrs: { sm: "6", md: "3" } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-center" },
                      [
                        _c("div", {
                          staticClass: "text-body-1 pt-4 pr-2",
                          domProps: { textContent: _vm._s("Filtros:") },
                        }),
                        _c("base-autocomplete-field", {
                          attrs: {
                            items: _vm.optionsStatus,
                            id: "status",
                            name: "status",
                            label: "Status",
                            "item-text": "titulo",
                            "item-value": "valor",
                            outlined: "",
                          },
                          on: { input: _vm.debounceInput },
                          model: {
                            value: _vm.filtros.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.filtros, "status", $$v)
                            },
                            expression: "filtros.status",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "text-center" }, [
                _vm._v(" " + _vm._s(_vm.descricaoRegistrosEncontrados) + " "),
              ]),
              _c(
                "div",
                { staticStyle: { "overflow-y": "auto", height: "350px" } },
                [
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.table.headers,
                      items: _vm.desserts,
                      "single-select": _vm.table.singleSelect,
                      "item-key": "id",
                      "show-select": "",
                      "hide-default-footer": "",
                      "disable-pagination": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.nome`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(" " + _vm._s(item.nome || "--") + " "),
                            ]
                          },
                        },
                        {
                          key: `item.telefone`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.telefone
                                      ? `(${item.telefone.ddd}) ${item.telefone.numero}`
                                      : "--"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.email`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(" " + _vm._s(item.email || "--") + " "),
                            ]
                          },
                        },
                        {
                          key: `item.status`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(" " + _vm._s(item.status || "--") + " "),
                            ]
                          },
                        },
                        {
                          key: `item.dataHoraTratamento`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("convertDatetimeFormat")(
                                      item.dataHoraTratamento
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "no-data",
                          fn: function () {
                            return [_vm._v(" Nenhum resultado encontrado ")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.table.selected,
                      callback: function ($$v) {
                        _vm.$set(_vm.table, "selected", $$v)
                      },
                      expression: "table.selected",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-2", attrs: { justify: "end" } },
                [
                  _c("v-col", { attrs: { sm: "6", md: "2" } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-none mt-4",
                            attrs: {
                              "data-test-id": "vincular-proposta",
                              id: "vincular-proposta",
                              type: "button",
                              elevation: "10",
                              block: "",
                              color: "primary",
                              disabled: _vm.disableBtnVinculoProposta,
                              loading: _vm.loadingBtn,
                            },
                            on: { click: _vm.vincularLead },
                          },
                          [_vm._v(" Vincular Proposta ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }