<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('closedialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1" style="line-height: 1">
              Ficha de Filiação
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Informações sobre a ficha de filiação
            </h3>
          </div>
        </v-toolbar-title>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>

    <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>

    <div v-else>
      <v-container class="pa-5 pt-0">
        <v-row no-gutters>
          <v-col cols="12" class="mb-2 mt-2">
            <b>Aceitou preencher a ficha digital?</b> <br />
            {{
              propostaState.dadosComplementaresFiliacao &&
              propostaState.dadosComplementaresFiliacao.aceitaFiliacao == true
                ? "Sim"
                : propostaState.dadosComplementaresFiliacao &&
                  propostaState.dadosComplementaresFiliacao.aceitaFiliacao ==
                    false
                ? "Não"
                : "--"
            }}
          </v-col>
        </v-row>
        <v-divider class="mb-4 mt-2"></v-divider>
        <v-row v-if="entidadeComFiliacaoApiPropria && msgTaxa" no-gutters class="mb-2">
          <v-col cols="6" class="pb-2">
            <b>Taxa de Filiação</b> <br />
            {{ msgTaxa }}
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-2" >
          <v-col cols="6" v-for="(c, index) in campos" :key="index" class="pb-2">
            <b>{{ c.titulo }}</b> <br />
            {{ c.valor != "" ? c.valor : "--" }}
          </v-col>
        </v-row>
      </v-container>
      <v-footer fixed v-if="showEditButton">
        <v-col class="text-center pa-1" cols="12">
          <v-btn large color="primary" class="secundaryBtn--text" @click="edit"> Editar dados </v-btn>
        </v-col>
      </v-footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Etapas } from "@/utils/etapas";
import propostaService from "@/services/propostaService";
import entidadesService from "@/services/entidadesService";

export default {
  props: {
    showBotao: Boolean,
    etapa: Object,
    validarEdicao: Function,
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
      entidadeState: (state) => state.cadastroProposta.titular.entidade,
      profissaoState: (state) => state.cadastroProposta.titular.publicoAlvo,
      planoState: (state) => state.cadastroProposta.proposta.plano,
      enderecoState: (state) => state.cadastroProposta.titular.enderecos,
      titularState: (state) => state.cadastroProposta.titular,
      user: (state) => state.user.profile,
    }),
    showEditButton() {
      if (this.entidadeComFiliacaoApiPropria) return false;
      this.carregarEtapa();
      const aguardandoAssinaturaEtapa = this.objEtapas.getEtapaAtual(
        "aguardando_assinatura",
      );
      const assinaturaRecusadaEtapa = this.objEtapas.getEtapaAtual(
        "assinatura_recusada",
      );
      const devolvidaEtapa = this.objEtapas.getEtapaAtual("devolvida");
      const adendoRecusadoEtapa = this.objEtapas.getEtapaAtual(
        "adendo_recusado",
      );
      if (
        (this.etapa
          && this.etapa.ordem >= this.etapaDadosFiliacao.ordem
          && this.etapa.ordem < aguardandoAssinaturaEtapa.ordem)
        || this.etapa.ordem === assinaturaRecusadaEtapa.ordem
        || (this.etapa.ordem === devolvidaEtapa.ordem
          && this.propostaState.usuarioTratando != null
          && this.propostaState.usuarioTratando.id === this.user.id)
        || this.etapa.ordem === adendoRecusadoEtapa.ordem
      ) {
        return true;
      }
      return false;
    },
    entidadeComFiliacaoApiPropria() {
      const entidadeComFiliacaoApiPropria = ['ABRACEM', 'ASPROFILI', 'ANAPROLIE'].includes(this.entidadeState)
      return entidadeComFiliacaoApiPropria
    }
  },
  data: () => ({
    etapaDadosFiliacao: {},
    loading: false,
    campos: [],
    objEtapas: {},
    msgTaxa: null,
  }),
  mounted() {
    this.getCampos();
  },
  methods: {
    ...mapActions({
      setBlnContinuar: "cadastroProposta/setBlnContinuar",
    }),
    carregarEtapa() {
      const objEtapas = new Etapas();
      objEtapas.loadEtapas();
      this.etapaDadosFiliacao = objEtapas.getEtapaAtual("dados_filiacao");
      this.objEtapas = objEtapas;
    },
    async edit() {
      const permiteEditar = await this.validarEdicao();
      if (!permiteEditar) {
        this.$emit('showmsgeditar');
        this.$emit('closedialog');
        return;
      }

      this.carregarEtapa();
      this.setBlnContinuar({
        flag: true,
        page: this.etapaDadosFiliacao.ordem,
        isEditing: true,
      });
      this.$router.push({ name: "areaLogada.criarProposta" });
    },
    async getCampos() {
      try {
        if (
          this.propostaState.dadosComplementaresFiliacao
          && this.propostaState.dadosComplementaresFiliacao.aceitaFiliacao === true
        ) {
          this.loading = true;
          let campos = await propostaService.getCamposFiliacao({
            guidProposta: this.propostaState.guid,
          });

          if (this.entidadeComFiliacaoApiPropria) {
            const { idProdutoFatura } = this.planoState;
            const { taxa } = await entidadesService.consultarTaxaAssociativa(idProdutoFatura);
            if (taxa) this.msgTaxa = taxa.existeTaxa ? "Não isento da cobrança contribuição associativa." : "Isento da cobrança contribuição associativa.";

            const { temFilhos } = await propostaService.getFiliacaoEntidade(this.titularState.cpf, this.entidade);
            campos = campos.map((item) => {
              if (item.campo === 'enderecoNumero') item.valor = this.enderecoState[0].numero;
              else if (item.campo === 'planoCnpj') item.valor = this.planoState.cnpjOperadora;
              else if (item.campo === 'subproduto') item.valor = this.planoState.operadora;
              else if (item.campo === 'temFilhos') item.valor = temFilhos && temFilhos == '1' ? "Sim" : "Não";
              else if (item.campo === 'profissao') item.valor = this.profissaoState;
              return item;
            });
          }

          this.campos = campos;
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.$root.$snackBar.open({ color: "error", message: error.message });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
