var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closedialog")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Dados Plano ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Mais informações do plano selecionado ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-2 mb-15" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { staticClass: "mb-2", attrs: { cols: "12" } }, [
                _c("b", [_vm._v("Nome")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      (_vm.propostaState &&
                        _vm.propostaState.plano &&
                        _vm.propostaState.plano.nome) ||
                        "--"
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("b", [_vm._v("Valor Total")]),
                _c("br"),
                _vm.propostaState &&
                _vm.propostaState.plano &&
                _vm.propostaState.plano.valorTotalOdontoAdicional &&
                _vm.propostaState.plano.valorTotalOdontoAdicional > 0.0
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("currency")(
                              parseFloat(_vm.propostaState.plano.valorTotal) +
                                parseFloat(
                                  _vm.propostaState.plano
                                    .valorTotalOdontoAdicional
                                )
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm.propostaState.valorTotal <
                    ((_vm.propostaState.plano &&
                      _vm.propostaState.plano.valorTotalSemDesconto) ||
                      -1)
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "text-decoration-line-through text-caption grey--text",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.propostaState.plano.valorTotalSemDesconto
                              )
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.propostaState.valorTotalProposta
                          )
                        )
                      ),
                    ]),
              ]),
            ],
            1
          ),
          _c("v-divider", { staticClass: "mb-4 mt-2" }),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { staticClass: "mb-2", attrs: { cols: "12" } }, [
                _c("b", [_vm._v("Operadora")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      (_vm.propostaState.plano &&
                        _vm.propostaState.plano.nomeExibicaoOperadora) ||
                        _vm.plano.operadora ||
                        "--"
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { staticClass: "mb-2", attrs: { cols: "4" } }, [
                _c("b", [_vm._v("Abrangência")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      (_vm.propostaState.plano &&
                        _vm.propostaState.plano.abrangencia) ||
                        "--"
                    ) +
                    " "
                ),
              ]),
              _c("v-col", { staticClass: "mb-2", attrs: { cols: "4" } }, [
                _c("b", [_vm._v("Acomodação")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      (_vm.propostaState.plano &&
                        _vm.propostaState.plano.acomodacao) ||
                        "--"
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { staticClass: "mb-2", attrs: { cols: "4" } }, [
                _c("b", [_vm._v("Segmentação")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      (_vm.propostaState.plano &&
                        _vm.propostaState.plano.segmentacao) ||
                        "--"
                    ) +
                    " "
                ),
              ]),
              _c("v-col", { staticClass: "mb-2", attrs: { cols: "4" } }, [
                _c("b", [_vm._v("Súmula")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.propostaState.possuiSumula ? "Sim" : "Não" || "--"
                    ) +
                    " "
                ),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _vm.propostaState.plano &&
                  _vm.propostaState.plano.coparticipacao
                    ? _c("v-chip", { staticClass: "px-3 mr-2 my-2" }, [
                        _vm._v("Coparticipação"),
                      ])
                    : _vm._e(),
                  _vm.propostaState.plano &&
                  _vm.propostaState.plano.modeloCoparticipacao
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "px-3 mr-2 my-2",
                          attrs: { color: "#00cfb6" },
                        },
                        [
                          _vm._v(
                            "Copart " +
                              _vm._s(
                                _vm.propostaState.plano.modeloCoparticipacao
                              )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.propostaState.plano && _vm.propostaState.plano.odonto
                    ? _c("v-chip", { staticClass: "px-3 my-2" }, [
                        _vm._v("Com Odonto"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.propostaState.hasOwnProperty("flagProdutoOpcional")
            ? _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { staticClass: "mb-2", attrs: { cols: "4" } }, [
                    _c("b", [_vm._v("Produtos opcionais")]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.propostaState.produtosOpcionais &&
                            _vm.propostaState.produtosOpcionais.length > 0
                            ? "Sim"
                            : "Não"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "h1",
            {
              staticClass: "title font-weight-bold mb-1 mt-4 primary--text",
              staticStyle: { "line-height": "1" },
            },
            [_vm._v(" Beneficiários ")]
          ),
          _c("v-divider", { staticClass: "mb-2 mt-2" }),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            _vm._l(_vm.beneficiarios, function (item, i) {
              return _c(
                "v-col",
                {
                  key: i,
                  staticClass: "mt-2 mr-2",
                  attrs: { cols: "12", md: "4", sm: "6" },
                },
                [
                  _c("base-card-beneficiary", {
                    attrs: {
                      avatar: _vm.getTipo(item.tipo),
                      nome: item.nome || item.dataNascimento,
                      valor: item.valor,
                      valorSemDesconto: item.valorSemDesconto,
                      tipo: item.tipo,
                      comOdonto: item.comOdonto,
                      valorOdonto: item.valorOdonto || 0,
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
          _c("br"),
          _vm.produtosOpcionais.length > 0
            ? _c(
                "div",
                [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1 primary--text",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Produtos opcionais ")]
                  ),
                  _c("v-divider", { staticClass: "mb-4 mt-2" }),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mt-0 mr-2", attrs: { cols: "12" } },
                        [
                          _c("card-produto-opcional", {
                            attrs: { produtosOpcionais: _vm.produtosOpcionais },
                            model: {
                              value: _vm.dialogProdutoOpcional,
                              callback: function ($$v) {
                                _vm.dialogProdutoOpcional = $$v
                              },
                              expression: "dialogProdutoOpcional",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.propostaState.recusaProdutosOpcionais.length > 0
            ? _c(
                "div",
                [
                  _c(
                    "h1",
                    {
                      staticClass:
                        "title font-weight-bold mb-1 mt-3 primary--text",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Recusas cliente ")]
                  ),
                  _c("v-divider", { staticClass: "mb-4 mt-2" }),
                  _vm._l(
                    _vm.propostaState.recusaProdutosOpcionais,
                    function (opcionalRecusado) {
                      return _c(
                        "p",
                        { key: opcionalRecusado.idProdutoFatura },
                        [
                          _vm._v(" - Opcional "),
                          _c("b", [_vm._v(_vm._s(opcionalRecusado.nome))]),
                          _vm._v(" recusado pelo cliente "),
                        ]
                      )
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showEditButton
        ? _c(
            "v-footer",
            [
              _c(
                "v-col",
                {
                  staticClass: "text-center pa-1",
                  attrs: { cols: "12" },
                  on: { click: _vm.edit },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secundaryBtn--text",
                      attrs: {
                        large: "",
                        color: "primary",
                        loading: _vm.loadingEdit,
                      },
                    },
                    [_vm._v(" Editar dados ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("v-footer", { attrs: { color: "white" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }