<template>
    <v-expansion-panels v-if="produtosOpcionais" class="d-flex justify-start flex-wrap">
        <v-col cols="6" md="6" sm="12" class="pa-0" v-for="(produtoOpcional, index) in produtosOpcionais" :key="index">
            <v-expansion-panel>
                <v-expansion-panel-header tile>
                    <v-card-text class="espacamento-linhas pa-0">
                    <v-row no-gutters>
                        <v-col cols="2" md="3" class="text-lg-center mt-0">
                        <v-icon size="45">mdi-file-document-multiple</v-icon>
                        </v-col>
                        <v-col cols="8" md="7" class="text-center">
                            <span class="mb-0 espacamento-linhas"></span><br>
                            <strong><span class="mb-0 espacamento-linhas">{{produtoOpcional.nomePlanoAns || 'PRODUTO OPCIONAL'}}</span></strong>
                        </v-col>
                    </v-row>
                    </v-card-text>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                     <v-col cols="6">
                        <v-row>
                        <v-col class="pb-0">
                            <v-list-item class="pa-0">
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Nome</v-list-item-title>
                                    <v-list-item-subtitle
                                    class="subtitle-1 text-uppercase text-wrap"
                                    style="line-height: 1.2;"
                                    >{{produtoOpcional.nomePlanoAns || '--'}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="pa-0">
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Valor Total</v-list-item-title>
                                    <v-list-item-subtitle
                                    class="subtitle-1 text-uppercase text-wrap"
                                    style="line-height: 1.2;"
                                    >{{produtoOpcional.valorTotal || '--'}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        </v-row>
                    </v-col>
                    </v-row>
                    <v-divider class="mb-2 mt-2"></v-divider>
                    <v-row>
                        <v-col class="pb-0">
                            <v-list-item class="pa-0">
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Operadora</v-list-item-title>
                                    <v-list-item-subtitle class="subtitle-1 text-uppercase">{{produtoOpcional.operadora || '--'}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Abrangência</v-list-item-title>
                                    <v-list-item-subtitle class="subtitle-1 text-uppercase">{{produtoOpcional.abrangencia || '--'}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="pa-0">
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Segmentação</v-list-item-title>
                                    <v-list-item-subtitle class="subtitle-1 text-uppercase">{{produtoOpcional.segmentacao || '--'}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Reembolso</v-list-item-title>
                                    <v-list-item-subtitle class="subtitle-1 text-uppercase">{{exibirReembolso(produtoOpcional) || '--'}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="pa-0">
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Observação</v-list-item-title>
                                    <v-list-item-subtitle class="subtitle-1 text-uppercase">{{produtoOpcional.observacaoPlano || '--'}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                    <h1 v-if="!produtoOpcional.opcionalExigeConfirmacaoCliente" class="title font-weight-bold mb-1 mt-4 primary--text" style="line-height: 1">
                        Beneficiários
                    </h1>
                    <v-divider class="mb-2 mt-2"></v-divider>
                    <v-row v-if="!produtoOpcional.opcionalExigeConfirmacaoCliente" no-gutters>
                        <v-col cols="12" md="12" sm="12" class="mt-2 mr-2" v-for="(beneficiario, indexTt) in beneficiarioTitular(produtoOpcional)" :key="indexTt">
                            <base-card-beneficiary
                            :nome="propostaState.titular.nome"
                            :valor="beneficiario.valor"
                            :tipo="isTipo(propostaState.titular.nome)"
                            :avatar="isTipo(propostaState.titular.nome)"
                            ></base-card-beneficiary>
                        </v-col>
                        <v-col cols="12" md="12" sm="12" class="mt-2 mr-2" v-for="(beneficiario, indexDp) in beneficiariosDependentes(produtoOpcional)" :key="indexDp+1">
                            <base-card-beneficiary
                            :nome="beneficiario.nome"
                            :valor="beneficiario.valor"
                            :tipo="isTipo(beneficiario.nome)"
                            :avatar="isTipo(beneficiario.nome)"
                            ></base-card-beneficiary>
                        </v-col>
                    </v-row>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-col>
    </v-expansion-panels>
</template>

<script>
import BaseCardBeneficiary from '@/components/BaseCardBeneficiary.vue';
import { mapState } from 'vuex';

export default {
  name: "ProdutosOpcionais",
  data() {
    return {
    };
  },
  components: { BaseCardBeneficiary },
  props: {
    produtosOpcionais: {
      type: Array,
      required: true,
    },
  },
  created() {},
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
  },
  methods: {
    beneficiarioTitular(produtoOpcional) {
      return this.propostaState.titular.produtosOpcionais.filter((e) => e.idProdutoFatura === produtoOpcional.idProdutoFatura);
    },

    beneficiariosDependentes(produtoOpcional) {
      if (this.propostaState.dependentes != null && this.propostaState.dependentes.length > 0) {
        const response = [];
        for (let cont = 0; cont < this.propostaState.dependentes.length; cont++) {
          const dependente = this.propostaState.dependentes[cont];

          const [beneficiario] = dependente.produtosOpcionais.filter((e) => e.idProdutoFatura === produtoOpcional.idProdutoFatura);
          beneficiario.nome = dependente.nome;
          response.push(beneficiario);
        }
        return response;
      } return [];
    },
    isTipo(nomeBeneficiario) {
      const nome = this.propostaState.titular.nome != null
        && this.propostaState.titular.nome !== ''
        ? this.propostaState.titular.nome
        : 'Titular';
      return nomeBeneficiario === nome ? 'Titular' : 'Dependente';
    },
    exibirReembolso(produtoOpcional) {
      return produtoOpcional.reembolso === true ? "SIM" : "NÃO";
    },
  },
};
</script>
