/* eslint-disable indent */
import api from '@/services/api';
import AppError from '@/utils/appError';

const resource = 'contratos';

const obterContrato = async (guid, tipo) => {
  try {
    let queryParam = '';

    switch ((tipo || "").toUpperCase()) {
      case 'CONTRATO':
        queryParam = '';
        break;
      case 'PREVIA':
        queryParam += '&previa=true';
        break;
      case 'CONTRATO_MARCA_DAGUA':
        queryParam += '&marca_dagua=true';
        break;
      default:
        throw new AppError('Opção inválida (obterContratoPrevia)');
    }
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CONTRATOS_API_KEY } };
    const response = await api.get(`${resource}/${guid}/consultar/?visualizar=true${queryParam}`, config);
    return response;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const atualizarContrato = async (guidProposta, atualizarContratoMarcaDagua) => {
  const marcaDagua = atualizarContratoMarcaDagua === true;
  const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CONTRATOS_API_KEY } };
  await api.post(`${resource}/${guidProposta}`, {
    atualizarContratoMarcaDagua: marcaDagua,
  }, config);
};

export default {
  obterContrato,
  atualizarContrato,
};
