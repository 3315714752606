var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closedialog")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Anexos alterados ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Relação de anexos alterados ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-2 mb-10" },
        [
          !_vm.anexosAlterados
            ? _c(
                "div",
                { staticClass: "d-flex justify-center mt-10 pa-10" },
                [
                  _c("v-progress-circular", {
                    attrs: { color: "primary", indeterminate: "" },
                  }),
                ],
                1
              )
            : _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center mb-2", attrs: { cols: "12" } },
                    [_vm._v(" " + _vm._s(_vm.quantidadeAlteracoes) + " ")]
                  ),
                ],
                1
              ),
          _vm._l(_vm.arquivosAnexosAlterados, function (item, i) {
            return _c(
              "v-card",
              { key: i, staticClass: "mb-4" },
              [
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "3", lg: "1" },
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { color: "primary", size: "55" } },
                              [_vm._v("mdi-account-circle-outline")]
                            ),
                          ],
                          1
                        ),
                        _c("v-col", { attrs: { cols: "9", lg: "11" } }, [
                          _c(
                            "span",
                            { staticClass: "font-weight-bold primary--text" },
                            [_vm._v(" " + _vm._s(item.nomeBeneficiario) + " ")]
                          ),
                          _c("br"),
                        ]),
                      ],
                      1
                    ),
                    _c("v-simple-table", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticClass:
                                          "text-left subtitle-1 font-weight-bold",
                                      },
                                      [_vm._v(" Anexos alterados ")]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(item.documentos, function (dado, d) {
                                    return _c("tr", { key: d }, [
                                      _c(
                                        "td",
                                        [
                                          _vm.checkIfDocumentIsPdf(dado.link)
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "anexos-image-pdf-icon",
                                                  attrs: { size: 40 },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.zoom(
                                                        dado.link,
                                                        dado.valorNovo,
                                                        false,
                                                        dado.campo
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("mdi-file-pdf-box")]
                                              )
                                            : _c(
                                                "v-img",
                                                _vm._b(
                                                  {
                                                    staticClass: "anexos-image",
                                                    attrs: {
                                                      height: 80,
                                                      width: 80,
                                                      position: "top",
                                                      src: dado.link,
                                                      alt: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.zoom(
                                                          dado.link,
                                                          dado.valorNovo,
                                                          false,
                                                          dado.campo
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-img",
                                                  _vm.$attrs,
                                                  false
                                                )
                                              ),
                                        ],
                                        1
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm.showBotao
        ? _c(
            "v-footer",
            { attrs: { fixed: "" } },
            [
              _c(
                "v-col",
                { staticClass: "text-center pa-1", attrs: { cols: "12" } },
                [
                  _vm.anexosAlterados
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "secundaryBtn--text",
                          attrs: {
                            large: "",
                            color: "primary",
                            disabled: _vm.botaoInfos.segundosParaHabilitar > 0,
                          },
                          on: { click: _vm.enviarAnexosAssinatura },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.botaoInfos.segundosParaHabilitar > 0 &&
                                  !_vm.loading
                                  ? `${_vm.botaoInfos.nome} ${_vm.botaoInfos.segundosParaHabilitar}`
                                  : `${_vm.botaoInfos.nome}`
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.preview.show,
            callback: function ($$v) {
              _vm.$set(_vm.preview, "show", $$v)
            },
            expression: "preview.show",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { tile: "", dark: "", color: "black" } },
            [
              _c(
                "v-app-bar",
                { attrs: { color: "black", dark: "", fixed: "" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "d-flex align-center pa-0 pa-md-4" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-3",
                          attrs: { small: "", icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              _vm.preview.show = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                        1
                      ),
                      _c("v-toolbar-title", { staticClass: "ml-2" }, [
                        _vm._v("Documento"),
                      ]),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { height: "70px" } }),
              _c(
                "v-container",
                { staticClass: "d-flex justify-center pa-4" },
                [
                  _c("v-img", {
                    attrs: {
                      "max-width": "800",
                      width: "100%",
                      src: _vm.preview.file,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }