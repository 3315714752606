<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('closedialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1" style="line-height: 1">
              Assinaturas
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Status de Assinaturas
            </h3>
          </div>
        </v-toolbar-title>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>

    <v-container class="pa-5 pt-2">
      <div class="d-flex border-top pt-4 pb-4" v-for="(item, i) in assinaturaState" :key="i">
        <v-icon v-if="item.assinado" color="success" large>mdi-checkbox-marked-circle-outline</v-icon>
        <v-icon v-else color="gray" large>mdi-information-outline</v-icon>
        <span class="ml-5 pt-2 primary--text">{{ item.nome }}</span>
      </div>
    </v-container>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import propostaService from "@/services/propostaService";

export default {
  created() {
    this.getAssinaturas();
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
  },
  data() {
    return {
      assinaturaState: [],
    };
  },
  methods: {
    async getAssinaturas() {
      const { guid: guidProposta } = this.propostaState;
      try {
        const assinaturas = await propostaService.getAssinaturas({ guidProposta });
        this.assinaturaState = assinaturas.data.data;
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao carregar assinaturas, tente novamente mais tarde",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  .header-height {
    padding: 20px;
    height: 70px;
  }

  .border-top {
    border-top-style: solid;
    border-top-width: thin;
    border-top-color: #E0E0E0;
  }

</style>
