export class Etapas {
  constructor() {
    this.etapas = [];
  }

  loadEtapas() {
    this.etapas.push(this.etapaDadosBasicos());
    this.etapas.push(this.etapaAssociarCorretora());
    this.etapas.push(this.etapaSelecaoOperadora());
    this.etapas.push(this.etapaSelecaoPlano());
    this.etapas.push(this.etapaSelecaoVigencia());
    this.etapas.push(this.etapaDadosTitular());
    this.etapas.push(this.etapaDadosResponsavel());
    this.etapas.push(this.etapaDadosProfissionais());
    this.etapas.push(this.etapaDadosFiliacao());
    this.etapas.push(this.etapaDadosDependentes());
    this.etapas.push(this.etapaSelecaoProdutosOpcionais());
    this.etapas.push(this.etapaDadosPortabilidade());
    this.etapas.push(this.etapaDadosFinanceiros());
    this.etapas.push(this.etapaAnexos());
    this.etapas.push(this.etapaAguardandoAssinatura());
    this.etapas.push(this.etapaPreenchido());
    this.etapas.push(this.etapaErroValidacao());
    this.etapas.push(this.etapaAssinaturaRecusada());
    this.etapas.push(this.etapaContratoAssinado());
    this.etapas.push(this.etapaProntaTransmissao());
    this.etapas.push(this.etapaAguardandoAssinaturaAdendo());
    this.etapas.push(this.etapaAdendoAssinado());
    this.etapas.push(this.etapaEmAnalise());
    this.etapas.push(this.etapaDevolvida());
    this.etapas.push(this.etapaAdendoRecusado());
    this.etapas.push(this.etapaFilaTransmissao());
    this.etapas.push(this.etapaErroTransmissao());
    this.etapas.push(this.etapaCancelada());
    this.etapas.push(this.etapaImplantada());
    this.etapas.push(this.etapaFinalizada());
    this.etapas.push(this.etapaNaoRecomendada());
    this.etapas.push(this.etapaSolicitadoDevolucao());
    this.etapas.push(this.etapaPropostaClonada());
    this.etapas.push(this.etapaPropostaClonadaSemSupervisor());
    this.etapas.push(this.etapaAguardandoEnvioAnexos());
    this.etapas.push(this.etapaAguardandoAceiteAnexos());
    this.etapas.push(this.etapaAnexosRecusados());
    this.etapas.push(this.etapaAnexosAceitos());
    this.etapas.push(this.etapaCanceladaPreVigencia());
  }

  getEtapaAtual(etapa) {
    return this.etapas.find(({ id }) => id === etapa);
  }

  etapaAssociarCorretora() {
    return {
      id: 'corretora',
      ordem: 0,
      titulo: 'Em digitação',
      subtitulo: 'Associar Corretora',
      page: 0,
    };
  }

  etapaDadosBasicos() {
    return {
      id: 'basicos',
      ordem: 1,
      titulo: 'Em digitação',
      subtitulo: 'Dados Básicos',
      page: 1,
    };
  }

  etapaSelecaoOperadora() {
    return {
      id: 'operadora',
      ordem: 2,
      titulo: 'Em digitação',
      subtitulo: 'Seleção Operadora',
      page: 2,
    };
  }

  etapaSelecaoPlano() {
    return {
      id: 'plano',
      ordem: 4,
      titulo: 'Em digitação',
      subtitulo: 'Seleção Plano',
      page: 4,
    };
  }

  etapaSelecaoVigencia() {
    return {
      id: 'vigencia',
      ordem: 3,
      titulo: 'Em digitação',
      subtitulo: 'Seleção Vigência',
      page: 3,
    };
  }

  etapaDadosTitular() {
    return {
      id: 'dados_titular_contratacao',
      ordem: 5,
      titulo: 'Em digitação',
      subtitulo: 'Dados Titular',
      page: 5,
    };
  }

  etapaDadosResponsavel() {
    return {
      id: 'dados_responsavel_contratacao',
      ordem: 6,
      titulo: 'Em digitação',
      subtitulo: 'Dados Responsável',
      page: 6,
    };
  }

  etapaDadosProfissionais() {
    return {
      id: 'dados_profissionais',
      ordem: 7,
      titulo: 'Em digitação',
      subtitulo: 'Dados Profissionais',
      page: 7,
    };
  }

  etapaDadosFiliacao() {
    return {
      id: 'dados_filiacao',
      ordem: 8,
      titulo: 'Em digitação',
      subtitulo: 'Dados Filiacao',
      page: 8,
    };
  }

  etapaDadosDependentes() {
    return {
      id: 'dependentes_contratacao',
      ordem: 9,
      titulo: 'Em digitação',
      subtitulo: 'Dados Dependentes',
      page: 9,
    };
  }

  etapaDadosPortabilidade() {
    return {
      id: 'dados_portabilidade',
      ordem: 10,
      titulo: 'Em digitação',
      subtitulo: 'Dados Portabilidade',
      page: 10,
    };
  }

  etapaSelecaoProdutosOpcionais() {
    return {
      id: 'produto_opcional',
      ordem: 11,
      titulo: 'Em digitação',
      subtitulo: 'Produtos Opcionais',
      page: 11,
    };
  }

  etapaDadosFinanceiros() {
    return {
      id: 'dados_financeiros',
      ordem: 12,
      titulo: 'Em digitação',
      subtitulo: 'Dados Financeiros',
      page: 12,
    };
  }

  etapaAnexos() {
    return {
      id: 'anexo',
      ordem: 13,
      titulo: 'Em digitação',
      subtitulo: 'Anexo',
      page: 13,
    };
  }

  etapaPreenchido() {
    return {
      id: 'preenchido',
      ordem: 14,
      titulo: 'Em digitação',
      subtitulo: 'Preenchido',
      rota: '',
    };
  }

  etapaErroValidacao() {
    return {
      id: 'erro_validacao',
      ordem: 15,
      titulo: 'Em digitação',
      subtitulo: 'Erro Validação',
      rota: '',
    };
  }

  etapaAguardandoAssinatura() {
    return {
      id: 'aguardando_assinatura',
      ordem: 16,
      titulo: 'Preenchimento cliente',
      subtitulo: 'Aguardando Assinatura',
      page: 0,
    };
  }

  etapaAssinaturaRecusada() {
    return {
      id: 'assinatura_recusada',
      ordem: 17,
      titulo: 'Análise corretor',
      subtitulo: 'Assinatura Recusada',
      rota: '',
    };
  }

  etapaContratoAssinado() {
    return {
      id: 'contrato_assinado',
      ordem: 18,
      titulo: 'Proposta assinada',
      subtitulo: 'Pronta Transmissão',
      rota: '',
    };
  }

  etapaProntaTransmissao() {
    return {
      id: 'pronta_transmissao',
      ordem: 19,
      titulo: 'Proposta assinada',
      subtitulo: 'Pronta Transmissão',
      rota: '',
    };
  }

  etapaAguardandoAssinaturaAdendo() {
    return {
      id: 'aguardando_assinatura_adendo',
      ordem: 20,
      titulo: 'Devolvida',
      subtitulo: 'Aguardando Adendo',
      rota: '',
    };
  }

  etapaAdendoAssinado() {
    return {
      id: 'adendo_assinado',
      ordem: 21,
      titulo: 'Devolvida',
      subtitulo: 'Adendo Assinado',
      rota: '',
    };
  }

  etapaEmAnalise() {
    return {
      id: 'em_analise',
      ordem: 22,
      titulo: 'Em análise',
      subtitulo: 'Transmissão',
      rota: '',
    };
  }

  etapaDevolvida() {
    return {
      id: 'devolvida',
      ordem: 23,
      titulo: 'Devolvida',
      subtitulo: 'Devolvida',
      rota: '',
    };
  }

  etapaAdendoRecusado() {
    return {
      id: 'adendo_recusado',
      ordem: 24,
      titulo: 'Devolvida',
      subtitulo: 'Adendo Recusado',
      rota: '',
    };
  }

  etapaFilaTransmissao() {
    return {
      id: 'fila_transmissao',
      ordem: 25,
      titulo: 'Fila Transmissão',
      subtitulo: 'Fila Transmissão',
      rota: '',
    };
  }

  etapaErroTransmissao() {
    return {
      id: 'erro_transmissao',
      ordem: 26,
      titulo: 'Fila Transmissão',
      subtitulo: 'Erro Transmissão',
      rota: '',
    };
  }

  etapaCancelada() {
    return {
      id: 'cancelada',
      ordem: 27,
      titulo: 'Cancelada',
      subtitulo: 'Cancelada',
      rota: '',
    };
  }

  etapaImplantada() {
    return {
      id: 'implantada',
      ordem: 28,
      titulo: 'Implantada',
      subtitulo: 'Implantada',
      rota: '',
    };
  }

  etapaFinalizada() {
    return {
      id: 'finalizada',
      ordem: 29,
      titulo: 'Finalizada',
      subtitulo: 'Finalizada',
      rota: '',
    };
  }

  etapaNaoRecomendada() {
    return {
      id: 'nao_recomendada',
      ordem: 30,
      titulo: 'Não Recomendada',
      subtitulo: 'Não Recomendada',
      rota: '',
    };
  }

  etapaSolicitadoDevolucao() {
    return {
      id: 'solicitado_devolucao',
      ordem: 31,
      titulo: 'Em análise',
      subtitulo: 'Solicitado Devolução',
      rota: '',
    };
  }

  etapaAguardandoEnvioAnexos() {
    return {
      id: 'aguardando_envio_anexos',
      ordem: 32,
      titulo: 'Em digitação',
      subtitulo: 'Aguardando envio de aceite de anexos',
      rota: '',
    };
  }

  etapaAguardandoAceiteAnexos() {
    return {
      id: 'aguardando_aceite_anexos',
      ordem: 33,
      titulo: 'Em digitação',
      subtitulo: 'Aguardando aceite de anexos',
      rota: '',
    };
  }

  etapaAnexosRecusados() {
    return {
      id: 'anexos_recusados',
      ordem: 34,
      titulo: 'Em digitação',
      subtitulo: 'Anexos recusados',
      rota: '',
    };
  }

  etapaAnexosAceitos() {
    return {
      id: 'anexos_aceitos',
      ordem: 35,
      titulo: 'Em digitação',
      subtitulo: 'Anexos aceitos',
      rota: '',
    };
  }

  etapaCanceladaPreVigencia() {
    return {
      id: 'cancelada_pre_vigencia',
      ordem: 36,
      titulo: 'Cancelada pré-vigência',
      subtitulo: 'Cancelada pré-vigência',
      rota: '',
    };
  }

  etapaPropostaClonada() {
    return {
      id: 'clonada',
      ordem: 1,
      titulo: 'Em digitação',
      subtitulo: 'Clone de Proposta',
      page: 1,
    };
  }

  etapaPropostaClonadaSemSupervisor() {
    return {
      id: 'clonada_sem_supervisor',
      ordem: 0,
      titulo: 'Em digitação',
      subtitulo: 'Clone de Proposta',
      page: 0,
    };
  }
}
