var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closedialog")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Contrato ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Visualização de Contrato ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _vm.loadingDocs
        ? _c(
            "div",
            { staticClass: "d-flex justify-center mt-10 pa-10" },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : _vm.contrato
        ? _c(
            "v-container",
            { staticClass: "pa-4 pt-0" },
            [
              _c("v-row", [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-subtitle-1 font-weight-medium primary--text my-4 ml-2",
                  },
                  [_vm._v(" Clique abaixo para fazer o download: ")]
                ),
              ]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        {
                          staticClass:
                            "pa-1 d-flex float-left align-center justify-center mr-2 p-relative",
                          staticStyle: { overflow: "hidden" },
                          attrs: { elevation: "3", height: 230, width: 230 },
                        },
                        [
                          _vm.pdf.loading
                            ? _c(
                                "v-col",
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "justify-center" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "red", size: "80" } },
                                        [_vm._v("mdi-file-pdf")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "justify-center ml-2 mr-2" },
                                    [
                                      _c("v-progress-linear", {
                                        attrs: {
                                          indeterminate: "",
                                          color: "red",
                                          height: "2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "v-img",
                                _vm._b(
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      position: "top",
                                      src: `data:image/png;base64,${_vm.pdf.urlShow}`,
                                      alt: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.zoom(_vm.pdf.link)
                                      },
                                    },
                                  },
                                  "v-img",
                                  _vm.$attrs,
                                  false
                                )
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              !_vm.pdf.loading && !_vm.loadingDocs
                ? _c(
                    "v-container",
                    { staticClass: "pa-5 pt-0 mt-8" },
                    [
                      _c("v-row", { staticClass: "justify-center" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.folder,
                            width: "180",
                            height: "180",
                          },
                        }),
                      ]),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "text-center pa-1 mt-5" },
                            [
                              _c("span", { staticClass: "primary--text" }, [
                                _vm._v(_vm._s(_vm.aviso)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
      _c(
        "v-footer",
        { attrs: { fixed: "" } },
        [
          _c(
            "v-col",
            { staticClass: "text-right pa-1" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled:
                      _vm.pdf.loading ||
                      _vm.loadingDocs ||
                      !_vm.existePrevia ||
                      _vm.disableAtualizar,
                    large: "",
                    color: "primary",
                  },
                  on: { click: _vm.atualizarContrato },
                },
                [_vm._v(" Atualizar Contrato ")]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "text-left pa-1" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "secundaryBtn--text",
                  attrs: { large: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closedialog")
                    },
                  },
                },
                [_vm._v(" Voltar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }