<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('closedialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1" style="line-height: 1">
              Anexos alterados
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Relação de anexos alterados
            </h3>
          </div>
        </v-toolbar-title>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>

    <v-container class="pa-5 pt-2 mb-10">
      <div v-if="!anexosAlterados" class="d-flex justify-center mt-10 pa-10">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
      </div>
      <v-row v-else no-gutters>
        <v-col cols="12" class="text-center mb-2">
          {{ quantidadeAlteracoes }}
        </v-col>
      </v-row>
      <v-card v-for="(item, i) in arquivosAnexosAlterados" :key="i" class="mb-4">
        <v-card-text>
          <v-row>
            <v-col cols="3" lg="1" class="text-center">
              <v-icon color="primary" size="55">mdi-account-circle-outline</v-icon>
            </v-col>
            <v-col cols="9" lg="11">
              <span class="font-weight-bold primary--text">
                {{ item.nomeBeneficiario }}
              </span><br>
            </v-col>
          </v-row>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left subtitle-1 font-weight-bold">
                    Anexos alterados
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(dado, d) in item.documentos" :key="d">
                  <td>
                    <v-icon @click="zoom(dado.link, dado.valorNovo, false, dado.campo)" :size="40" class="anexos-image-pdf-icon"
                      v-if="checkIfDocumentIsPdf(dado.link)">mdi-file-pdf-box</v-icon>
                    <v-img
                      v-else
                      class="anexos-image"
                      :height="80"
                      :width="80"
                      position="top"
                      v-bind="$attrs"
                      :src="dado.link"
                      @click="zoom(dado.link, dado.valorNovo, false, dado.campo)"
                      alt
                  ></v-img>
                </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-container>

    <v-footer fixed v-if="showBotao">
      <v-col class="text-center pa-1" cols="12" >
        <v-btn v-if="anexosAlterados" large color="primary" class="secundaryBtn--text" @click="enviarAnexosAssinatura"  :disabled="botaoInfos.segundosParaHabilitar > 0">
          {{ botaoInfos.segundosParaHabilitar > 0 && !loading ? `${botaoInfos.nome} ${botaoInfos.segundosParaHabilitar}` : `${botaoInfos.nome}`}}
        </v-btn>
      </v-col>
    </v-footer>

      <v-dialog v-model="preview.show">
        <v-card tile dark color="black">
          <v-app-bar color="black" dark fixed>
            <v-container class="d-flex align-center pa-0 pa-md-4">
              <v-btn class="mr-3" small icon dark @click="preview.show = false">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>

              <v-toolbar-title class="ml-2">Documento</v-toolbar-title>

              <v-spacer></v-spacer>
            </v-container>
          </v-app-bar>
          <div style="height: 70px"></div>
          <v-container class="d-flex justify-center pa-4">
            <v-img max-width="800" width="100%" :src="preview.file"></v-img>
          </v-container>
        </v-card>
      </v-dialog>

  </div>
</template>

<script>
import propostaService from '@/services/propostaService';
import { mapState } from 'vuex';

export default {
  props: {
    validarEnvioAssAdendo: Function,
  },
  data() {
    return {
      anexosAlterados: null,
      preview: {
        file: null,
        show: false,
      },
      botaoInfos: {
        nome: '',
        segundosParaHabilitar: 0,
      },
    };
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
    showBotao() {
      return this.propostaState.etapa === 'aguardando_envio_anexos' || this.propostaState.etapa === 'aguardando_aceite_anexos';
    },
    quantidadeAlteracoes() {
      if (this.anexosAlterados) {
        if (this.anexosAlterados.qtdAnexos === 1) {
          return '1 alteração registrada';
        }

        return `${this.anexosAlterados.qtdAnexos} alterações registradas`;
      }

      return '';
    },
    arquivosAnexosAlterados() {
      return this.anexosAlterados && this.anexosAlterados.participantes;
    },
  },
  async mounted() {
    await this.carregaDocumentosAlterados();
    this.getInfosBotao();
  },
  methods: {
    async carregaDocumentosAlterados() {
      try {
        const { guid: guidProposta } = this.propostaState;
        const data = await propostaService.buscarAlteracaoAnexos({
          guidProposta,
          retornaArquivos: true,
        });

        this.anexosAlterados = data;
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao buscar documentos, tente novamente mais tarde',
        });
      }
    },
    async enviarAnexosAssinatura() {
      if (this.botaoInfos.segundosParaHabilitar <= 0) {
        try {
          this.loading = true;
          this.botaoInfos.nome = "Enviando...";
          this.desabilitaPor(this.botaoInfos, 30);
          const { guid: guidProposta } = this.propostaState;
          await propostaService.enviarAssinaturaAnexos({ guidProposta });
          this.$emit('closedialog');
        } catch (error) {
          this.$root.$snackBar.open({
            color: 'error',
            message: 'Erro ao enviar anexos para assinatura, tente novamente mais tarde',
          });
        } finally {
          this.botaoInfos.nome = this.propostaState.etapa === 'aguardando_aceite_anexos' ? 'Reenviar para assinatura' : 'Enviar para assinatura';
          this.loading = false;
        }
      }
    },
    getInfosBotao() {
      this.botaoInfos.nome = this.propostaState.etapa === 'aguardando_aceite_anexos' ? 'Reenviar para assinatura' : 'Enviar para assinatura';
    },
    desabilitaPor(botao, segundos) {
      botao.segundosParaHabilitar = segundos;
      setTimeout(() => {
        botao.segundosParaHabilitar -= 1;
        if (botao.segundosParaHabilitar > 0) { this.desabilitaPor(botao, segundos - 1); }
      }, 1000);
    },
    zoom(file, guid, habilitadoRemove, origem) {
      if (file.indexOf('pdf') > 0) {
        window.open(file);
      } else {
        this.preview.file = file;
        this.preview.guid = guid;
        this.preview.show = true;
        this.preview.habilitadoRemove = habilitadoRemove;
        this.preview.origem = origem;
      }
    },
    checkIfDocumentIsPdf(link) {
      const linkSplit = link.split('.')
      if(!linkSplit || linkSplit.length === 0) return false

      const tipoAssinatura = linkSplit[linkSplit.length - 1]
      if(!tipoAssinatura || tipoAssinatura.length === 0) return false

      const tipo = tipoAssinatura.split('?')
      if(!tipo || tipo.length === 0) return false

      return tipo[0] === 'pdf'
    }
  },
};
</script>

<style lang="scss" scoped>

  .header-height {
    padding: 20px;
    height: 70px;
  }

  .border-top {
    border-top-style: solid;
    border-top-width: thin;
    border-top-color: #E0E0E0;
  }

</style>
