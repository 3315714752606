<template>
  <div>
    <pergunta
      ref="pergunta"
      @sim="sim"
      @nao="nao"
    />
    <vinculo-lead
      ref="vinculoLead"
      :leads="leads"
      @recarregardados="recarregarDados"
      @transmitir="transmitir"
    />
    <origem-venda
      ref="origemVenda"
      :origensVenda="origensVenda"
      @recarregardados="recarregarDados"
      @transmitir="transmitir"
    />
  </div>
</template>

<script>
import Pergunta from "./Pergunta/index.vue";
import VinculoLead from "./VinculoLead/index.vue";
import OrigemVenda from "./OrigemVenda/index.vue";

export default {
  components: {
    Pergunta,
    VinculoLead,
    OrigemVenda,
  },
  props: {
    leads: {
      type: Array,
    },
    origensVenda: {
      type: Array,
    },
  },
  data() {
    return {
      vincularLead: null,
    };
  },
  methods: {
    // PERGUNTA
    openDialogPergunta() {
      this.$refs.pergunta.openDialog();
    },
    closeDialogPergunta() {
      this.$refs.pergunta.closeDialog();
    },
    // VINCULO LEAD
    openDialogVinculoLead() {
      this.$refs.vinculoLead.openDialog();
    },
    closeDialogVinculoLead() {
      this.$refs.vinculoLead.closeDialog();
    },
    // ORIGEM DA VENDA
    openDialogOrigemVenda() {
      this.$refs.origemVenda.openDialog();
    },
    closeDialogOrigemVenda() {
      this.$refs.origemVenda.closeDialog();
    },
    // ----------------
    recarregarDados() {
      this.$emit('recarregardados');
    },
    transmitir() {
      this.$emit('transmitir');
    },
    sim() {
      this.openDialogVinculoLead();
      this.closeDialogPergunta();
    },
    nao() {
      if ((this.origensVenda || []).length) {
        this.openDialogOrigemVenda();
        this.closeDialogPergunta();
        return;
      }
      this.closeDialogPergunta();
      this.$refs.origemVenda.transmitir();
    },
  },
};
</script>
