<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('closedialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1" style="line-height: 1">
              Dados Dependentes
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Relação de dependentes
            </h3>
          </div>
        </v-toolbar-title>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>

    <v-container class="pa-5 pt-0" v-if="propostaState.dependentes.length > 0">
      <v-expansion-panels v-model="panel" multiple class="mb-12">
        <v-expansion-panel v-for="(item, i) in propostaState.dependentes" :key="i">
          <v-expansion-panel-header>
            <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">
              Dependente {{ i + 1 }}
            </h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="mb-4"></v-divider>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Nome</b> <br>
                {{ item && item.nome || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>CPF</b> <br>
                {{ item && item.cpf || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Data de Nascimento</b> <br>
                {{ item && item.dataNascimento | convertDateOutput }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Nome da Mãe</b> <br>
                {{ item && item.nomeMae || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Sexo</b> <br>
                {{ item && item.sexo || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Estado Civil</b> <br>
                {{ item && item.estadoCivil || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Grau de Parentesco</b> <br>
                {{ item && item.parentesco || '--' }}
              </v-col>
              <v-col v-if="item.dataEvento" cols="6" class="mb-2">
                <b>Data do Evento</b> <br>
                {{ item && item.dataEvento | convertDateOutput }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>PIS/PASEP</b> <br>
                {{ item && item.pisPasep || '--' }}
              </v-col>
              <v-col v-if="item.declaracaoNascidoVivo" cols="6" class="mb-2">
                <b>Declaração Nascido Vivo</b> <br>
                {{ item && item.declaracaoNascidoVivo || '--' }}
              </v-col>
              <v-col v-if="item.cns" cols="6" class="mb-2">
                <b>Cartão Nacional Saúde</b> <br>
                {{ item && item.cns || '--' }}
              </v-col>
              <!-- Campos exclusivos para unimed volta redonda -->

              <v-row no-gutters v-if="propostaState.plano && propostaState.plano.operadora.toLowerCase() === 'unimed volta redonda'">
              <v-col cols="6" class="mb-2">
                <b>Identidade</b> <br>
                {{ item && item.rg || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Orgão Emissor</b> <br>
                {{ item && item.rgOrgaoEmissor || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>UF Emissor</b> <br>
                {{ item && item.rgOrgaoEmissorUf || '--' }}
              </v-col>
              <v-col v-if="item && item.rgDataExpedicao" cols="6" class="mb-2">
                <b>Data Expedição</b> <br>
                {{ item.rgDataExpedicao | convertDateOutput }}
              </v-col>
              <v-col v-else cols="6" class="mb-2">
                <b>Data Expedição</b> <br>
                {{ '--' }}
              </v-col>
              </v-row>
              <template v-else>
                <v-col cols="6" class="mb-2">
                  <b>Identidade</b> <br />
                  {{ (item && item.rg) || "--" }}
                </v-col>
                <v-col cols="6" class="mb-2">
                  <b>Data Expedição</b> <br />
                  {{ item.rgDataExpedicao | convertDateOutput }}
                </v-col>
              </template>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Portabilidade</b> <br>
                {{ item && item.portabilidade != undefined ? item.portabilidade ? "Sim" : "Não" : propostaState.possuiPortabilidade ? "Sim" : "Não"  }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Segmentação Anterior</b> <br>
                {{ item && item.segmentacaoAnterior || '--' }}
              </v-col>
            </v-row>
          <v-row no-gutters>
            <v-col cols="6" class="mb-2">
              <b>Redução de carência</b> <br>
                {{ item && item.possuiPlano ? "Sim" : "Não" || '--' }}
            </v-col>
            <v-col cols="6" class="mb-2" v-if="propostaState.plano && propostaState.plano.operadora.toLowerCase() === 'unimed bh' ">
              <b>Carteirinha anterior</b> <br>
                {{ item.campoCarteirinha || '--' }}
            </v-col>
          </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
    <v-container class="pt-2 text-center h-100" v-else>
      Nenhum dependente cadastrado
    </v-container>

    <v-footer fixed v-if="showEditButton">
      <v-col class="text-center pa-1" cols="12" >
        <v-btn large color="primary" class="secundaryBtn--text" @click="edit">
          Editar dados
        </v-btn>
      </v-col>
    </v-footer>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Etapas } from '@/utils/etapas';

export default {
  props: {
    showBotao: Boolean,
    etapa: Object,
    validarEdicao: Function,
  },
  data() {
    return {
      panel: [],
      objEtapas: {},
      etapaDependentes: {},
    };
  },
  mounted() {
    this.expandirTodos();
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
    showEditButton() {
      this.carregarEtapa();
      return (this.etapa && this.etapa.ordem >= this.etapaDependentes.ordem && this.showBotao);
    },
  },
  methods: {
    ...mapActions({
      setBlnContinuar: 'cadastroProposta/setBlnContinuar',
    }),
    carregarEtapa() {
      const objEtapas = new Etapas();
      objEtapas.loadEtapas();
      this.etapaDependentes = objEtapas.getEtapaAtual("dependentes_contratacao");
    },
    expandirTodos() {
      this.panel = [...Array(this.propostaState.dependentes.length).keys()].map((k, i) => i);
    },
    async edit() {
      const permiteEditar = await this.validarEdicao();
      if (!permiteEditar) {
        this.$emit('showmsgeditar');
        this.$emit('closedialog');
        return;
      }

      this.carregarEtapa();
      this.setBlnContinuar({ flag: true, page: this.etapaDependentes.ordem, isEditing: true });
      this.$router.push({ name: 'areaLogada.criarProposta' });
    },
  },
};
</script>

<style lang="scss" scoped>

  .header-height {
    padding: 20px;
    height: 70px;
  }

</style>
