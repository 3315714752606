<template>
  <div>
    <div class="primary pa-2">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <div class="white--text">
              <h1 class="display-1 font-weight-regular mb-2" style="line-height: 1">
                Tudo certo!
              </h1>
              <h3 class="subtitle-1 ma-0" style="line-height: 1">
                A proposta foi transmitida com sucesso.
              </h3>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container class="pa-5 pb-5 pt-0">
      <div class="text-center mt-5">
        <v-icon color="primary" style="font-size: 300px">mdi-check-circle</v-icon>
      </div>
    </v-container>

    <v-footer fixed>
      <v-col class="text-center pa-1" cols="12" >
        <v-btn large color="primary" @click="$emit('concluir')">
          Concluir
        </v-btn>
      </v-col>
    </v-footer>

  </div>
</template>

<script>

export default {
  props: {
    param: Object,
  },
};
</script>

<style lang="scss" scoped>

</style>
